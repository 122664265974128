<section class="px-4 py-12 md:px-14 lg:px-[108px] md:py-16 bg-[#f2f2f2]" id="atomo-goal-container">
  <p id="atomo-goal"
    class="text-purple_100 text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36 md:text-center mb-10 lg:mb-16">
    Elevating Educational
    Excellence</p>
  <div class="grid grid-cols-2 gap-10 lg:gap-16">
    <div class="col-span-2 md:col-span-1 flex justify-center items-center">
      <div class="anim" lottie [options]="options" id="atomo-goal-illustration"></div>
    </div>
    <div class="col-span-2 md:col-span-1 md:flex md:items-center md:justify-center">
      <div class="flex flex-col gap-4" id="atomo-goal-description">
        <p class="text-hurrey-20 md:text-hurrey-24 lg:text-hurrey-28" id="atomo-goal-title">Education Matters</p>
        <p class="text-grey text-hurrey-16 break-all" id="atomo-goal-description">Atomo acts as the catalyst,
          smoothening <br class="hidden md:block">
          experiences and enhancing outcomes across all levels.</p>
      </div>
    </div>
  </div>
</section>