import { Component } from '@angular/core';
import { slideInUpOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'blog-decor',
  standalone: true,
  imports: [],
  templateUrl: './blog-decor.component.html',
  styleUrl: './blog-decor.component.scss',
  animations:[slideInUpOnEnterAnimation({ anchor: 'slideInOut' }),]
})
export class BlogDecorComponent {

}
