import { Component } from '@angular/core';

@Component({
  selector: 'atomos-tech-stack',
  standalone: true,
  imports: [],
  templateUrl: './atomos-tech-stack.component.html',
  styleUrl: './atomos-tech-stack.component.scss'
})
export class AtomosTechStackComponent {

}
