import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  slideInLeftOnEnterAnimation,
  slideInRightOnEnterAnimation,
} from 'angular-animations';

@Component({
  selector: 'our-innovations',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './our-innovations.component.html',
  styleUrl: './our-innovations.component.scss',
  animations: [
    slideInLeftOnEnterAnimation({ anchor: 'slideInLeft' }),
    slideInRightOnEnterAnimation({ anchor: 'slideInRight' }),
  ],
})
export class OurInnovationsComponent {
  innovations = [
    {
      img: '../../../../assets/images/feedback_machenism.svg',
      backgroundColor: '#9480FF',
      title: 'Feedback Mechanism',
      discription:
        "Atomo's feedback loop ensures precision-driven, personalized learning experiences by addressing unique student needs through error analysis and tailored feedback.",
    },
    {
      img: '../../../../assets/images/math_solver.svg',
      title: 'Math Solver',
      backgroundColor: '#6B4EFF',
      discription:
        'Transform your math learning with our innovative step-by-step solver, effortlessly mastering concepts and practicing with guided confidence.',
    },
    {
      img: '../../../../assets/images/interactive_learning.svg',
      title: 'Interactive Learning',
      backgroundColor: '#7C62FF',
      discription:
        'AI-driven platforms revolutionize education by adapting content in real time, fostering personalized engagement, and delivering dynamic assessments with adaptive feedback.',
    },
  ];
}
