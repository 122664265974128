<section
  class="h-screen physics_excellance_container flex flex-col items-center justify-center px-4 md:px-14 lg:px-[108px] "
  id="physics_excellance_container">
  <p class="mb-6 md:mb-4 lg:mb-6 text-center"> <span
      class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-48 text-black font-bold "
      id="physics_excellance_text">Building Tailored Physics <br class="block md:hidden"> Excellence,</span> <br
      class="hidden md:block"> <span class=" text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-48 text-purple_100">as
      a
      Team</span> </p>



  <p class="text-hurrey-16 text-black mb-14 md:mb-10 lg:mb-14 text-center " id="physics_excellance_description"
    id="physics_solver_usp"> Shape
    your individualized physics
    exploration <br class="block md:hidden"> and
    enhance your comprehension <br class="hidden md:block lg:hidden"> of concepts <br class="block md:hidden"> with
    hands-on experiments at <br class="block md:hidden"> center.
  </p>

  <!-- <button class="get_app_button" id="get-app"> Get App</button> -->

</section>