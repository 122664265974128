import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { team } from '../../../shared/models/our-team.model';

@Component({
  selector: 'our-team',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './our-team.component.html',
  styleUrl: './our-team.component.scss'
})
export class OurTeamComponent {



  team_profile: team[] = [
    { img: '../../../../assets/images/sreekesh.webp', name: 'Sreekesh Kulkarni', designation: 'Lead Developer' },
    { img: '../../../../assets/images/arvind.webp', name: 'Arvind Samriya', designation: 'Sr. ML Engineer' },
    { img: '../../../../assets/images/mejo.webp', name: 'Mejo Mohan ', designation: 'Sr. UI/UX Designer' },
    { img: '../../../../assets/images/hassan.webp', name: 'Mohammad Hasan Nawaz', designation: 'Sales Manager' },
    { img: '../../../../assets/images/shnatha.webp', name: 'Shantha Kumar Nagesh Gari', designation: 'Quality Assurance Engineer' },
    { img: '../../../../assets/images/dipti.webp', name: 'Dipti Sanap', designation: 'Machine Learning Engineer' },
    { img: '../../../../assets/images/sujay.webp', name: 'Sujay Shetty ', designation: 'UI/UX Designer' },
    { img: '../../../../assets/images/shuchi.webp', name: 'Shuchi Rastogi', designation: 'UI/UX Designer' },
    { img: '../../../../assets/images/nidhi.webp', name: 'Nidhi Kumari', designation: 'Software Development Engineer' }
  ]



}
