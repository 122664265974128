import { NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { slideInLeftOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'our-partner-foundries',
  standalone: true,
  imports: [NgOptimizedImage, RouterLink],
  templateUrl: './our-partner-foundries.component.html',
  styleUrl: './our-partner-foundries.component.scss',
  animations: [
    slideInLeftOnEnterAnimation({ anchor: 'slideInOut' })
  ]
})
export class OurPartnerFoundriesComponent {

}
