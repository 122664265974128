import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'atomo-ai',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './atomo-ai.component.html',
  styleUrl: './atomo-ai.component.scss',



})
export class AtomoAiComponent {

}
