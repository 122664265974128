<section class=" flex flex-col pt-6 px-4 md:px-14 lg:px-[108px]" id="hurrey_scope_features_container">
  <p class="text-hurrey-16 text-purple_100 mb-3"> HurreyScope's Key Features</p>
  <p class=" text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36 font-bold text-black mb-11 md:mb-8">Unlock Your
    Potential</p>

  <div class="hidden md:block" id="hurrey_scope_features">
    <div class="grid grid-cols-3 md:gap-4 lg:gap-6 mb-14 ">
      <div *ngFor="let item of potential;let i = index">
        <div class="col-span-1 features_card px-4 py-10">
          <div class="flex flex-col">
            <div class="flex items-center justify-center">
              <img [src]="item.img" [alt]="item.title" class="images mb-4">
            </div>
            <p class=" text-hurrey-16 md:text-hurrey-20 lg:text-hurrey-24 text-black font-bold mb-2"
              [id]="'hurrey_scope_feature_'+i"> {{ item.title}}
            </p>
            <p class=" text-hurrey-16  md:text-hurrey-20 lg:text-hurrey-24 text-black font-bold mb-4"
              [id]="'hurrey_scope_feature_topic_'+i"> {{item.topic}}
            </p>
            <p class=" text-hurrey-12 lg:text-hurrey-16 text-black " [id]="'hurrey_scope_feature_'+i+'_description'"> {{
              item.description}}</p>
          </div>
        </div>
      </div>

    </div>

  </div>

  <div class="block md:hidden " id="hurrey_scope_features_mobile">
    <div class="horizontal_scroll">
      <div class="flex gap-4 lg:gap-6 mb-14  ">
        <div *ngFor="let item of potential;let i =index">
          <div class="features_card_mobile py-10 px-4">
            <div class="flex flex-col">
              <div class="flex items-center justify-center">
                <img [src]="item.img" [alt]="item.title" class="images mb-4">
              </div>
              <p class=" text-hurrey-24 md:text-hurrey-16 lg:text-hurrey-24 text-black font-bold mb-2"
                [id]="'hurrey_scope_feature_'+i"> {{ item.title}}
              </p>
              <p class=" text-hurrey-24 md:text-hurrey-16 lg:text-hurrey-24 text-black font-bold mb-4"
                [id]="'hurrey_scope_feature_topic_'+i"> {{item.topic}}
              </p>
              <p class=" text-hurrey-16 md:text-hurrey-12 lg:text-hurrey-16 text-black "
                [id]="'hurrey_scope_feature_'+i+'_description'"> {{ item.description}}</p>
            </div>

          </div>

        </div>

      </div>
    </div>
  </div>

  <p class="text-hurrey-16 text-purple_100 mb-3"> HurreyScope's Benefits</p>
  <p class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36 font-bold text-black mb-11 md:mb-8">Why Choose
    HurreyScope </p>

  <div class="hidden md:block" id="hurrey_scope_benefits">
    <div class="grid grid-cols-3 md:gap-4 lg:gap-6 mb-14 ">
      <div *ngFor="let item of benefits;let i = index">
        <div class="col-span-1 features_card px-4 py-10">
          <div class="flex flex-col">
            <div class="flex items-center justify-center">
              <img [src]="item.img" [alt]="item.title" class="images mb-4">
            </div>
            <p class=" text-hurrey-16  md:text-hurrey-20 lg:text-hurrey-24 text-black font-bold mb-2"
              [id]="'hurrey_scope_benifit_'+i"> {{ item.title}}
            </p>
            <p class=" text-hurrey-16  md:text-hurrey-20 lg:text-hurrey-24 text-black font-bold mb-4"
              [id]="'hurrey_scope_benefit_topic_'+i"> {{item.topic}}
            </p>
            <p class=" text-hurrey-12 lg:text-hurrey-16 text-black " [id]="'hurrey_scope_benefit_'+i+'_description'"> {{
              item.description}}</p>
          </div>
        </div>
      </div>

    </div>

  </div>

  <div class="block md:hidden " id="hurrey_scope_benefits_mobile">
    <div class="horizontal_scroll">
      <div class="flex  gap-4 lg:gap-6 mb-14  ">
        <div *ngFor="let item of benefits;let i = index">
          <div class="features_card_mobile py-10 px-4">
            <div class="flex flex-col">
              <div class="flex items-center justify-center">
                <img [src]="item.img" [alt]="item.title" class="images mb-4">
              </div>
              <p class=" text-hurrey-24 md:text-hurrey-16 lg:text-hurrey-24 text-black font-bold mb-2"
                [id]="'hurrey_scope_benifit_'+i"> {{ item.title}}
              </p>
              <p class=" text-hurrey-24 md:text-hurrey-16 lg:text-hurrey-24 text-black font-bold mb-4"
                [id]="'hurrey_scope_benefit_topic_'+i"> {{item.topic}}
              </p>
              <p class=" text-hurrey-16 md:text-hurrey-12 lg:text-hurrey-16 text-black "
                [id]="'hurrey_scope_benefit_'+i+'_description'"> {{ item.description}}</p>
            </div>

          </div>

        </div>

      </div>
    </div>
  </div>

  <p class="text-hurrey-16 text-purple_100 mb-3"> Inside HurreyScope</p>
  <p class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36 font-bold text-black mb-11 md:mb-8">Unveiling the Process
  </p>


  <div class="grid grid-cols-1" id="hurrey_scope_process">
    <div class="col-span-1 flex flex-col gap-4 md:gap-8 relative">
      <div *ngFor="let item of unveiling_process , let i = index">
        <div class="flex gap-32 rounded-md py-6 pl-4  pr-[108px] " [ngStyle]="{
        'background-color':
          i == 0 ? '#D3CAFF' :
          i == 1 ? '#FFE9B3' :
          i == 2 ? '#C1D9F6' :
          '#6B4EFF'
      }">
          <div class="flex flex-col gap-3">
            <p class="text-hurrey-16 font-bold text-black" [id]="'hurrey_scope_process_step'+i">{{item.step}} </p>
            <p class="text-hurrey-24 font-bold text-black " [id]="'hurrey_scope_process_step'+i+'_title'">
              {{item.title}} </p>
            <p class="text-hurrey-16 text-black" [id]="'hurrey_scope_process_step'+i+'_description'">
              {{item.description}} </p>
          </div>
          <div class="hidden md:flex  absolute right-0 ">
            <img [src]="item.img" [alt]="item.title">
          </div>

        </div>
      </div>

    </div>
  </div>




</section>