<section class="math-solution-container px-4 md:px-14 lg:px-[108px]" id="math-solution-container">
  <div class="grid grid-cols-2 gap-10 md:gap-28 lg:gap-14">
    <div
      class="col-span-2 md:col-span-1 flex flex-col gap-10 md:gap-6 items-start justify-start md:justify-center   pt-0 md:pt-40 lg:pt-60 pb-44 md:pb-20 lg:pb-32 ">
      <p id="math-solver-usp"><span class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-48 text-black font-bold">
          Unlock Detailed Math <br class="block md:hidden">
          Solutions
          for</span> <span class="text-amber_100 text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-48 font-bold"> Every
          <br>
          Problem</span> </p>

      <!-- <button class="get_app_button" type="button" id="get-app-button"> Get App </button> -->

    </div>

    <div
      class="col-span-2 md:col-span-1 pt-40 md:pt-28 lg:pt-40 flex items-start justify-end md:justify-center order-first md:order-last pb-0 md:pb-14 lg:pb-10 ">
      <img src="../../../../assets/images/math-solution-device.webp" alt="math_solver_device" class="images">
    </div>


  </div>

</section>