import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Output,
} from '@angular/core';
import { log } from 'node:console';
import { Subscription, fromEvent, filter } from 'rxjs';

@Directive({
  selector: '[clickOutside]',
  standalone: true,
})
export class ClickOutsideDirective {
  @Output() clickOutside: EventEmitter<void> = new EventEmitter<void>();

  documentClickSubscription!: Subscription;

  constructor(
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngAfterViewInit(): void {
    // throw new Error('Method not implemented.');
    this.documentClickSubscription = fromEvent(this.document, 'click')
      .pipe(
        filter((event) => {
          return !this.isInside(event.target as HTMLElement);
        })
      )
      .subscribe({
        next: () => {
          this.clickOutside.emit();
        },
      });
  }

  isInside(elementToCheck: HTMLElement): boolean {
    // console.log(this.elementRef.nativeElement.contains(elementToCheck));
    return this.elementRef.nativeElement.contains(elementToCheck);
  }

  ngOnDestroy(): void {
    this.documentClickSubscription?.unsubscribe();
  }
}
