import { Component } from '@angular/core';

@Component({
  selector: 'elevating-education-with-ai',
  standalone: true,
  imports: [],
  templateUrl: './elevating-education-with-ai.component.html',
  styleUrl: './elevating-education-with-ai.component.scss'
})
export class ElevatingEducationWithAiComponent {

}
