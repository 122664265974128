import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Data, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter, map, mergeMap, tap } from 'rxjs';
import { MetaService } from './shared/services/meta.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'atomos-site';


  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private metaService: MetaService){}

  ngOnInit(): void {
    this.router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
      tap(({name,description}: Data) => {
         this.metaService.updateTitle(name);
         this.metaService.updateDescription(description);
       })
    ).subscribe();
  }
}
