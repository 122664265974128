import { Component } from '@angular/core';
import { PhysicsExcellenceComponent } from '../../physics_solver_inner_pages/physics-excellence/physics-excellence.component';
import { PhysicsSolverFeaturesComponent } from '../../physics_solver_inner_pages/physics-solver-features/physics-solver-features.component';
import { BuildYourJourneyComponent } from '../../physics_solver_inner_pages/build-your-journey/build-your-journey.component';
import { ScienceBehindPhysicsSolverComponent } from '../../physics_solver_inner_pages/science-behind-physics-solver/science-behind-physics-solver.component';
import { ProfessionalForPhysicsSolverComponent } from '../../physics_solver_inner_pages/professional-for-physics-solver/professional-for-physics-solver.component';

@Component({
  selector: 'app-physics-solver',
  standalone: true,
  imports: [PhysicsExcellenceComponent, PhysicsSolverFeaturesComponent, BuildYourJourneyComponent, ScienceBehindPhysicsSolverComponent, ProfessionalForPhysicsSolverComponent],
  templateUrl: './physics-solver.component.html',
  styleUrl: './physics-solver.component.scss'
})
export class PhysicsSolverComponent {

}
