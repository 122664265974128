import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { enableProdMode, isDevMode } from '@angular/core';
import { environment } from './environments/environment';

bootstrapApplication(AppComponent, appConfig)
  .then(() => {
    if (environment.production) {
      console.log(' I m in prod');
      enableProdMode();
    } else {
      console.log('In Dev Mode');
    }
    if (isDevMode()) {
      console.log('dev');
    } else {
      console.log('prod');
    }
  })
  .catch((err) => console.error(err));
