import { Component } from '@angular/core';

@Component({
  selector: 'app-science-behind-language-learner',
  standalone: true,
  imports: [],
  templateUrl: './science-behind-language-learner.component.html',
  styleUrl: './science-behind-language-learner.component.scss'
})
export class ScienceBehindLanguageLearnerComponent {

}
