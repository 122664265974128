<section id="usecases_main_container"
  class="h-screen slide px-4 md:px-14 lg:px-[108px] flex items-center relative bg-amber_100">
  <div class="flex flex-col gap-10" [@fadeInLeftOnEnter]>
    <div class="flex flex-col gap-4 text-white">
      <p class="text-hurrey-28 lg:text-hurrey-48">Revolutionizing <br class="block md:hidden"> Education <br
          class="hidden md:block"> Through <br class="block md:hidden"> Use Cases</p>
      <!-- <button class="explore_button" routerLink="/join-us" id="usecases_join_button">Join</button> -->
    </div>
  </div>
</section>

<section class="py-6" id="usecases_container">

  <div [id]="card.head" class="use_case_accordion px-4 md:px-14 lg:px-[108px]"
    *ngFor="let card of useCaseCards;let i =index">
    <div class="accordion_head py-6 cursor-pointer" (click)="onCardClick(i)">
      <div class="flex justify-between">
        <div class="flex gap-2 lg:gap-6">
          <div class="flex justify-center items-center">
            <p class="text-hurrey-28 md:text-hurrey-48">{{i+1}}</p>
          </div>
          <div style="flex: 1 0 0;">
            <p class="text-hurrey-16 md:text-hurrey-24 lg:text-hurrey-28" style="flex: 1 0 0;"
              [class.text-sky_dark]="!card.isExpanded">{{card.head}}</p>
            <p class="max-w-[90%] md:max-w-none text-hurrey-12 lg:text-hurrey-16 text-grey" style="flex: 1 0 0;"
              [class.text-sky_dark]="!card.isExpanded">
              {{card.subHead}}</p>
          </div>
        </div>

        <div class="flex justify-center items-center">
          <i-tabler name="chevron-down" class="custom-icon" [ngClass]="{'up': card.isExpanded}"></i-tabler>
        </div>
      </div>
    </div>
    <div *ngIf="card.isExpanded" [@expandOnEnter] [@collapseOnLeave] class="accordion_body flex flex-col gap-6 pb-6">
      <ng-container *ngIf="i==0">
        <ng-container *ngTemplateOutlet="atomoForSchools"></ng-container>
      </ng-container>

      <ng-container *ngIf="i==1">
        <ng-container *ngTemplateOutlet="atomoForTeachers"></ng-container>
      </ng-container>

      <ng-container *ngIf="i==2">
        <ng-container *ngTemplateOutlet="atomoForParents"></ng-container>
      </ng-container>


      <ng-container *ngIf="i==3">
        <ng-container *ngTemplateOutlet="atomoForStudents"></ng-container>
      </ng-container>

      <ng-container *ngIf="i==4">
        <ng-container *ngTemplateOutlet="atomoForFunders"></ng-container>
      </ng-container>

      <ng-container *ngIf="i==5">
        <ng-container *ngTemplateOutlet="atomoForIntegrators"></ng-container>
      </ng-container>


      <ng-container *ngIf="i==6">
        <ng-container *ngTemplateOutlet="atomoForGovernment"></ng-container>
      </ng-container>

      <ng-container *ngIf="i==7">
        <ng-container *ngTemplateOutlet="atomoForNGO"></ng-container>
      </ng-container>


      <ng-container *ngIf="i==8">
        <ng-container *ngTemplateOutlet="atomoForComprehensive"></ng-container>
      </ng-container>

      <ng-container *ngIf="i==9">
        <ng-container *ngTemplateOutlet="atomoForProviders"></ng-container>
      </ng-container>

    </div>
  </div>
</section>


<ng-template #atomoForSchools id="atomoForSchools">
  <div class="flex flex-col-reverse gap-4 md:gap-0 md:flex-row justify-between">
    <div class="grid lg:grid-cols-3 grid-cols-2 gap-y-4 md:gap-x-2 lg:gap-x-6">
      <div class="col-span-2 lg:col-span-3">
        <p class="text-hurrey-16 md:text-hurrey-24">Atomo Make School Operation Smooth</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-1 flex flex-col gap-4">
        <p>Administrative Operations</p>
        <p class="flex gap-2 items-center">
          <i-tabler name="file-text" class="custom-icon" id="auto_admission"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Auto Admission</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="businessplan" class="custom-icon" id="budget_and_revenue"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Budget & Revenue</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="users" class="custom-icon" id="staff_optimization"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Staff Optimization</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="bus" class="custom-icon" id="fleet_logistics"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Fleet & Logistics</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="school" class="custom-icon" id="teacher_training"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Teacher Training</span>
        </p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-2 flex flex-col gap-4">
        <p>Academic Operations</p>
        <p class="flex gap-2 items-center">
          <i-tabler name="brand-speedtest" class="custom-icon" id="classroom_performance_index"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Classroom Performance
            Index</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="brand-speedtest" class="custom-icon" id="teacher_performance_index"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Teacher Performance
            Index</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="checkbox" class="custom-icon" id="auto_attendance"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Auto Attendance</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="circles" class="custom-icon" id="grievance_management"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Grievance
            Management</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="bell-ringing" class="custom-icon" id="meeting_notifications"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Meetings &
            Notifications</span>
        </p>
      </div>
    </div>
    <div class="content_image md:self-end">
      <img src="../../../../assets/images/atomo-for-school-1.webp" alt="teacher-image">
    </div>
  </div>



  <div class="flex flex-col md:flex-row justify-between">
    <div class="grid lg:grid-cols-3 grid-cols-2 gap-y-4 gap-x-6">
      <div class="col-span-2">
        <p class="text-hurrey-16 md:text-hurrey-24" id="atomo_nep_2020_policy">Atomo’s alignment with NEP 2020</p>
      </div>

      <div class="col-span-2 flex flex-col gap-4 md:max-w-[520px]">
        <p class="text-hurrey-12 md:text-hurrey-16 text-ink_light" id="atomo_nep_2020_policy_description">We must be
          able to measure the Learning Index /
          Outcomes of the
          child in multiple ways (quantitatively, qualitatively & contextually)​.</p>
        <p id="atomo_nep_2020_policy_sub_description" class="text-hurrey-12 md:text-hurrey-16 text-ink_light">
          Multi-level evaluation of the child will lead to
          a complete
          change in the Learning Quotient / Index being built​.</p>
      </div>
      <div class="col-span-2 md:col-span-1 md:col-start-1 flex flex-col gap-4">
        <p class="flex gap-2 items-center">
          <i-tabler name="chart-histogram" class="custom-icon" id="analytics_reporting_tools"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Analytics & ​Reporting
            Tools​</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="brand-speedtest" class="custom-icon" id="knowledge_index"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Knowledge Index</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="building-carousel" class="custom-icon" id="ai_powered_lms"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">AI - Powered LMS​</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="affiliate" class="custom-icon " id="virtual_labs_simulations"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Virtual Labs and
            Simulations​</span>
        </p>
      </div>
      <div class="col-span-2 md:col-span-1 md:col-start-2 flex flex-col gap-4">
        <p class="flex gap-2 items-center">
          <i-tabler name="language" class="custom-icon" id="language_learning_apps"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Language Learning ​
            Apps</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="book-download" class="custom-icon" id="interactive_digital_content"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Interactive Digital
            ​Content</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="layers-union" class="custom-icon" id="collaborative_paltforms"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Collaborative ​
            Platforms</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="clipboard-text" class="custom-icon" id="online_assessment"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Online Assessment​</span>
        </p>
      </div>
      <div class="col-span-2 flex flex-col gap-4 md:max-w-[520px]">
        <p class="text-hurrey-16 md:text-hurrey-24">2x your school’s Impact with Atomo</p>
        <p class="text-hurrey-12 md:text-hurrey-16 text-ink_light">As your school gets the AI upgrade, so does a
          neighboring school. Extend the benefits of AI advancement to
          the community. Empower education locally with every upgrade</p>
      </div>
    </div>
    <div class="content_image md:self-end">
      <img src="../../../../assets/images/atomo-for-school-2.webp" alt="student-image">
    </div>
  </div>
</ng-template>

<ng-template #atomoForTeachers id="atomoForTeachers">
  <div class="flex flex-col-reverse gap-2 md:gap-0 md:flex-row justify-between">
    <div class="grid grid-cols-2 lg:grid-cols-3 gap-y-4 gap-x-6">
      <div class="col-span-3">
        <p class="text-hurrey-16 md:text-hurrey-24">Helping Teachers Deliver More in Less Time</p>
      </div>
      <div class="col-span-2 md:col-span-1 md:col-start-1 flex flex-col gap-4">
        <p class="flex gap-2 items-center">
          <i-tabler name="brand-speedtest" class="custom-icon" id="student_performance_index"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Student Performance
            Index​</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="brand-speedtest" class="custom-icon" id="classroom_performance_index"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Classroom Performance
            Index</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="checkbox" class="custom-icon" id="teacher_development_index"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Teacher Development
            Index​</span>
        </p>
      </div>
      <div class="col-span-2 md:col-span-1 md:col-start-2 flex flex-col gap-4">
        <p class="flex gap-2 items-center">
          <i-tabler name="user-scan" class="custom-icon" id="ocr_on_smartphone"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">OCR on smartphone</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="circles" class="custom-icon" id="auto_evaluation"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Auto Evaluation</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="chart-bar" class="custom-icon" id="data_driven_insights"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Data Driven Insights</span>
        </p>
      </div>
    </div>
    <div class="content_image md:self-end">
      <img src="../../../../assets/images/atomo-for-teacher-1.webp" alt="teacher-image">
    </div>
  </div>

  <div class="flex flex-col-reverse gap-2 md:gap-0 md:flex-row justify-between">
    <div class="grid lg:grid-cols-3 grid-cols-2 gap-y-4 gap-x-6">
      <div class="col-span-2">
        <p class="text-hurrey-16 md:text-hurrey-24" id="unified_control_center">Unified Control Center</p>
      </div>

      <div class="col-span-2 flex flex-col gap-4 md:max-w-[520px]">
        <p class="text-hurrey-12 md:text-hurrey-16 text-ink_light">We recognize the value of time in education, hence
          our meticulously crafted infrastructure is tailored to seamlessly integrate into your teaching routine with
          minimal learning curves</p>
      </div>
      <div class="col-span-2 md:col-span-1 md:col-start-1 flex flex-col gap-4">
        <p class="flex gap-2 items-center">
          <i-tabler name="scoreboard" class="custom-icon" id="comprehension_score"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Comprehension Score​</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="device-mobile" class="custom-icon" id="smart_phone_whiteboard"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Smart Phone Whiteboard</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="camera" class="custom-icon" id="camera_based_assessment"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Camera Based
            Assessment​</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="brain" class="custom-icon"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">AI Recap​</span>
        </p>
      </div>
      <div class="col-span-2 md:col-span-1 md:col-start-2 flex flex-col gap-4">
        <p class="flex gap-2 items-center">
          <i-tabler name="chart-bar" class="custom-icon" id="classroom_insights"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Classroom Insights</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="chart-bar" class="custom-icon" id="student_insights"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Student Insights</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="device-ipad-question" class="custom-icon" id="ai_question_bank"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">AI Question Bank</span>
        </p>
      </div>
    </div>
    <div class="content_image_var_1 md:self-end">
      <img src="../../../../assets/images/atomo-for-teachers-2.png" alt="phone">
    </div>
  </div>


</ng-template>

<ng-template #atomoForParents id="atomoForParents">
  <div class="flex flex-col gap-4">
    <div class="flex flex-col-reverse gap-4 md:gap-0 md:flex-row justify-between">
      <div class="grid grid-cols-2 gap-y-4 md:gap-x-2 lg:gap-x-6">
        <div class="col-span-2">
          <p class="text-hurrey-16 md:text-hurrey-24">Helping Parents Realise the ROI</p>
        </div>
        <div class="col-span-2 md:col-span-1 lg:col-start-1 flex flex-col gap-1">
          <i-tabler name="user" class="custom-icon text-hurrey-16 md:text-hurrey-24"
            id="individualised_attention"></i-tabler>
          <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">Individualised
            Attention</p>
          <p class="md:max-w-[250px] text-hurrey-12 md:text-hurrey-16 text-ink_light">Students enjoy personalized
            troubleshooting and
            resources tuned to their unique
            learning needs and
            understanding levels.</p>
        </div>
        <div class="col-span-2 md:col-span-1 lg:col-start-2 flex flex-col gap-1">
          <i-tabler name="school" class="custom-icon text-hurrey-16 md:text-hurrey-24"
            id="enpowering_student_autonomy"></i-tabler>
          <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">Empowering Student Autonomy</p>
          <p class="md:max-w-[250px] text-hurrey-12 md:text-hurrey-16 text-ink_light">Atomo's AI empowers students to
            discover and resolve learning obstacles independently.</p>
        </div>
        <div class="col-span-2 md:col-span-1 lg:col-start-1 flex flex-col gap-1">
          <i-tabler name="chart-bar" class="custom-icon text-hurrey-16 md:text-hurrey-24"
            id="ai_powered_precision_insights"></i-tabler>
          <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">AI-Powered Precision Insights</p>
          <p class="md:max-w-[250px] text-hurrey-12 md:text-hurrey-16 text-ink_light">Gain detailed insights into your
            child's performance and learning.</p>
        </div>
      </div>
      <div class="content_image md:self-end">
        <img src="../../../../assets/images/atomo-for-parent.webp" alt="parent-image">
      </div>
    </div>
    <div class="flex flex-col gap-4">
      <p class="text-hurrey-16 md:text-hurrey-24">Helping Parents Realise the ROI</p>
      <p class="text-hurrey-12 md:text-hurrey-16 text-grey md:max-w-[80%] lg:max-w-[60%]">With Atomo, secure your
        child's future and support another's
        education. Your contribution extends beyond individual growth, fostering broader learning opportunities. Embrace
        Atomo for personalized education and collective impact. Empower your child while making a difference for others.
      </p>
    </div>
  </div>
</ng-template>



<ng-template #atomoForStudents id="atomoForStudents">
  <div class="flex flex-col-reverse gap-4 md:gap-0 md:flex-row justify-between">
    <div class="grid lg:grid-cols-3 grid-cols-2 gap-y-4 md:gap-x-2 lg:gap-x-6">
      <div class="col-span-2 lg:col-span-3">
        <p class="text-hurrey-16 md:text-hurrey-24">Enabling Students Navigate Independently</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-1 flex flex-col gap-4">
        <p class="flex gap-2 items-center">
          <i-tabler name="user" class="custom-icon" id="individualised_learning_path"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Individualised Learning
            Path</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="graph" class="custom-icon" id="root_cause_analysis"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Root Cause Analysis</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="users" class="custom-icon" id="realtime_feedback_loop"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Realtime Feedback Loop</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="language" class="custom-icon" id="language_learner"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Language Learner</span>
        </p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-2 flex flex-col gap-4">
        <p class="flex gap-2 items-center">
          <i-tabler name="notebook" class="custom-icon" id="collaborative_learning"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Collaborative Learning</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="file-code" class="custom-icon" id="predictive_algorithms"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Predictive Algorithms</span>
        </p>
        <p class="flex gap-2 items-center">
          <i-tabler name="square-root-2" class="custom-icon" id="maths_physics_solvers"></i-tabler>
          <span class="text-ink_light text-hurrey-12 md:text-hurrey-16" style="flex:1 0 0">Math/Physics Solvers - Step
            by Step</span>
        </p>
      </div>
    </div>
    <div class="content_image md:self-end">
      <img src="../../../../assets/images/atomo-for-student.webp" alt="student-image">
    </div>
  </div>

  <div class="flex flex-col-reverse gap-2 md:gap-0 md:flex-row justify-between">
    <div class="grid lg:grid-cols-3 grid-cols-2 gap-y-4 gap-x-6">
      <div class="col-span-2 flex flex-col gap-2 md:gap-4 md:max-w-[520px] justify-center">
        <p class="text-hurrey-16 md:text-hurrey-24">Solvers & Enablers for Students</p>
        <p class="text-hurrey-12 md:text-hurrey-16 text-ink_light">Atomo provides a suite of user-friendly, cutting-edge
          tools and apps tailored to empower students. Infused with machine learning and refined through extensive data
          sets, our sophisticated yet intuitive applications ensure stability and precision. With an array of over a
          dozen tools spanning diverse subjects, learners harness these resources to fuel their productivity and
          curiosity.</p>
      </div>

    </div>
    <div class="content_image_var_1 md:self-end">
      <img src="../../../../assets/images/atomo-for-students-2.png" alt="phone">
    </div>
  </div>
</ng-template>



<ng-template #atomoForFunders id="atomoForFunders">
  <div class="flex flex-col-reverse gap-4 md:gap-0 md:flex-row justify-between">
    <div class="grid grid-cols-2 gap-y-4 md:gap-x-2 lg:gap-x-6">
      <div class="col-span-2">
        <p class="text-hurrey-16 md:text-hurrey-24">Maximizing CSR Impact with Atomo's Education Solutions</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-1 flex flex-col gap-1">
        <i-tabler name="circles" class="custom-icon text-hurrey-16 md:text-hurrey-24" id="ngo_collobaration"></i-tabler>
        <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">NGO Collaboration</p>
        <p class="md:max-w-[250px] text-hurrey-12 md:text-hurrey-16 text-ink_light">Partner with Atomo's associated NGOs
          for efficient and targeted CSR program implementation.</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-2 flex flex-col gap-1">
        <i-tabler name="file-text" class="custom-icon text-hurrey-16 md:text-hurrey-24"
          id="educational_impact_reports"></i-tabler>
        <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">Educational Impact Reports</p>
        <p class="md:max-w-[250px] text-shurrey-16 text-ink_light">Receive detailed impact reports
          showcasing the influence of CSR investments on education outcomes.</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-1 flex flex-col gap-1">
        <i-tabler name="phone-calling" class="custom-icon text-hurrey-16 md:text-hurrey-24"
          id="consultative_approach"></i-tabler>
        <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">Consultative Approach</p>
        <p class="md:max-w-[250px] text-hurrey-12 md:text-hurrey-16 text-ink_light">Engage in consultations to identify
          specific education-focused needs and tailor solutions accordingly.</p>
      </div>
    </div>
    <div class="content_image md:self-end">
      <img src="../../../../assets/images/atomo-for-csr.webp" alt="csr-funder-image">
    </div>
  </div>
</ng-template>


<ng-template #atomoForIntegrators id="atomoForIntegrators">
  <div class="flex flex-col-reverse gap-4 md:gap-0 md:flex-row justify-between">
    <div class="grid grid-cols-2 gap-y-4 md:gap-x-2 lg:gap-x-6">
      <div class="col-span-2">
        <p class="text-hurrey-16 md:text-hurrey-24">Elevating Education Solutions for System Integrators</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-1 flex flex-col gap-1">
        <i-tabler name="table-options" class="custom-icon text-hurrey-16 md:text-hurrey-24"
          id="customixed_integration"></i-tabler>
        <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">Customized Integration</p>
        <p class="md:max-w-[250px] text-hurrey-12 md:text-hurrey-16 text-ink_light">Seamlessly incorporate Atomo's
          education solutions into your current service architecture.</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-2 flex flex-col gap-1">
        <i-tabler name="clipboard-data" class="custom-icon text-hurrey-16 md:text-hurrey-24"
          id="joint_sales_initiatives"></i-tabler>
        <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">Joint Sales Initiatives</p>
        <p class="md:max-w-[250px] text-shurrey-16 text-ink_light">Explore joint sales efforts to expand market reach
          and capitalize on mutual strengths.</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-1 flex flex-col gap-1">
        <i-tabler name="heart-handshake" class="custom-icon text-hurrey-16 md:text-hurrey-24"
          id="training_and_support"></i-tabler>
        <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">Training and Support</p>
        <p class="md:max-w-[250px] text-hurrey-12 md:text-hurrey-16 text-ink_light">Receive specialized training and
          ongoing support to efficiently integrate and maintain Atomo's offerings.</p>
      </div>
    </div>
    <div class="content_image md:self-end">
      <img src="../../../../assets/images/atomo-for-integrater.webp" alt="integrator-image">
    </div>
  </div>
</ng-template>


<ng-template #atomoForGovernment id="atomoForGovernment">
  <div class="flex flex-col-reverse gap-4 md:gap-0 md:flex-row justify-between">
    <div class="grid grid-cols-2 gap-y-4 md:gap-x-2 lg:gap-x-6">
      <div class="col-span-2">
        <p class="text-hurrey-16 md:text-hurrey-24">Empowering Government for Educational Transformation</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-1 flex flex-col gap-1">
        <i-tabler name="building" class="custom-icon text-hurrey-16 md:text-hurrey-24"
          id="district_wide_implementation"></i-tabler>
        <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">District-Wide Implementation</p>
        <p class="md:max-w-[250px] text-hurrey-12 md:text-hurrey-16 text-ink_light">Implement Atomo's solutions across
          the district to achieve digital inclusion and data-driven governance.</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-2 flex flex-col gap-1">
        <i-tabler name="file-text" class="custom-icon text-hurrey-16 md:text-hurrey-24"
          id="educational_initiatives"></i-tabler>
        <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">Educational Initiatives</p>
        <p class="md:max-w-[250px] text-shurrey-16 text-ink_light">Launch initiatives that leverage Atomo's AI-driven
          tools for impactful educational transformation.</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-1 flex flex-col gap-1">
        <i-tabler name="gavel" class="custom-icon text-hurrey-16 md:text-hurrey-24"
          id="government_partnership"></i-tabler>
        <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">Government Partnership</p>
        <p class="md:max-w-[250px] text-hurrey-12 md:text-hurrey-16 text-ink_light">Collaborate with government bodies
          to align Atomo's offerings with national and state-level educational priorities.</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-2 flex flex-col gap-1">
        <i-tabler name="books" class="custom-icon text-hurrey-16 md:text-hurrey-24"
          id="administrative_training"></i-tabler>
        <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">Administrative Training</p>
        <p class="md:max-w-[250px] text-shurrey-16 text-ink_light">Provide administrative training to facilitate the
          seamless integration of Atomo's education solutions into existing systems.</p>
      </div>
    </div>
    <div class="content_image md:self-end">
      <img src="../../../../assets/images/atomo-for-govt.webp" alt="goverment-image">
    </div>
  </div>
</ng-template>


<ng-template #atomoForNGO id="atomoForNGO">
  <div class="flex flex-col-reverse gap-4 md:gap-0 md:flex-row justify-between">
    <div class="grid grid-cols-2 gap-y-4 md:gap-x-2 lg:gap-x-6">
      <div class="col-span-2">
        <p class="text-hurrey-16 md:text-hurrey-24">Empowering Education NGOs for Future-Ready Partnerships</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-1 flex flex-col gap-1">
        <i-tabler name="elevator" class="custom-icon text-hurrey-16 md:text-hurrey-24 rotate-90"
          id="service_package_integration"></i-tabler>
        <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">Service Package Integration</p>
        <p class="md:max-w-[250px] text-hurrey-12 md:text-hurrey-16 text-ink_light">Receive dedicated support during the
          transition from existing solutions to Atomo's advanced offerings.</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-2 flex flex-col gap-1">
        <i-tabler name="file-text" class="custom-icon text-hurrey-16 md:text-hurrey-24"
          id="impact_assessment_tools"></i-tabler>
        <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">Impact Assessment Tools</p>
        <p class="md:max-w-[250px] text-shurrey-16 text-ink_light">Utilize tools provided by Atomo to assess and
          communicate the direct impact of educational initiatives</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-1 flex flex-col gap-1">
        <i-tabler name="brand-python" class="custom-icon text-hurrey-16 md:text-hurrey-24"
          id="educational_program_tailoring"></i-tabler>
        <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">Educational Program Tailoring</p>
        <p class="md:max-w-[250px] text-hurrey-12 md:text-hurrey-16 text-ink_light">Tailor Atomo's solutions to suit the
          unique needs and objectives of ongoing educational programs.</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-2 flex flex-col gap-1">
        <i-tabler name="home-stats" class="custom-icon text-hurrey-16 md:text-hurrey-24"
          id="mutual_growth_initiatives"></i-tabler>
        <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">Mutual Growth Initiatives</p>
        <p class="md:max-w-[250px] text-shurrey-16 text-ink_light">Explore joint initiatives that leverage the strengths
          of both parties for mutual business growth.</p>
      </div>
    </div>
    <div class="content_image md:self-end">
      <img src="../../../../assets/images/atomo-for-ngos.webp" alt="ngo's-image">
    </div>
  </div>
</ng-template>


<ng-template #atomoForComprehensive id="atomoForComprehensive">
  <div class="flex flex-col-reverse gap-4 md:gap-0 md:flex-row justify-between">
    <div class="grid grid-cols-2 gap-y-4 md:gap-x-2 lg:gap-x-6">
      <div class="col-span-2">
        <p class="text-hurrey-16 md:text-hurrey-24">Empowering Schools with Comprehensive Solutions</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-1 flex flex-col gap-1">
        <i-tabler name="parachute" class="custom-icon text-hurrey-16 md:text-hurrey-24" id="pilot_programs"></i-tabler>
        <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">Pilot Programs</p>
        <p class="md:max-w-[250px] text-hurrey-12 md:text-hurrey-16 text-ink_light">Implement pilot programs to
          experience the benefits of Atomo's education solutions firsthand.</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-2 flex flex-col gap-1">
        <i-tabler name="ad-2" class="custom-icon text-hurrey-16 md:text-hurrey-24"
          id="marketing_collaborations"></i-tabler>
        <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">Marketing Collaboration</p>
        <p class="md:max-w-[250px] text-shurrey-16 text-ink_light">Leverage Atomo's marketing solutions to improve
          admissions, branding, and overall visibility.</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-1 flex flex-col gap-1">
        <i-tabler name="copy" class="custom-icon text-hurrey-16 md:text-hurrey-24"
          id="customized_implementation_plans"></i-tabler>
        <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">Customized Implementation Plans</p>
        <p class="md:max-w-[250px] text-hurrey-12 md:text-hurrey-16 text-ink_light">Tailor implementation plans based on
          specific school needs, ensuring a smooth integration process.</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-2 flex flex-col gap-1">
        <i-tabler name="friends" class="custom-icon text-hurrey-16 md:text-hurrey-24" id="parent_engagement"></i-tabler>
        <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">Parent Engagement</p>
        <p class="md:max-w-[250px] text-shurrey-16 text-ink_light">Introduce Atomo's solutions as a valuable addition to
          improve parent-teacher engagement and collaboration.</p>
      </div>
    </div>
    <div class="content_image md:self-end">
      <img src="../../../../assets/images/atomo-for-school-1.webp" alt="teacher-image">
    </div>
  </div>
</ng-template>


<ng-template #atomoForProviders id="atomoForProviders">
  <div class="flex flex-col-reverse gap-4 md:gap-0 md:flex-row justify-between">
    <div class="grid grid-cols-2 gap-y-4 md:gap-x-2 lg:gap-x-6">
      <div class="col-span-2">
        <p class="text-hurrey-16 md:text-hurrey-24">Empowering Smart Class Solution Providers Globally</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-1 flex flex-col gap-1">
        <i-tabler name="ad-2" class="custom-icon text-hurrey-16 md:text-hurrey-24" id="market_collaboration"></i-tabler>
        <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">Marketing Collaboration
        </p>
        <p class="md:max-w-[250px] text-hurrey-12 md:text-hurrey-16 text-ink_light">Collaborate on joint marketing
          efforts to maximize outreach and attract a broader audience.</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-2 flex flex-col gap-1">
        <i-tabler name="exchange" class="custom-icon text-hurrey-16 md:text-hurrey-24"
          id="integration_flexibility"></i-tabler>
        <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">Integration Flexibility</p>
        <p class="md:max-w-[250px] text-shurrey-16 text-ink_light">Choose the level of integration that suits your
          business model, incorporating Atomo's solutions seamlessly.</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-1 flex flex-col gap-1">
        <i-tabler name="home-stats" class="custom-icon text-hurrey-16 md:text-hurrey-24"
          id="mutual_growth_initiatives"></i-tabler>
        <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">Mutual Growth Initiatives</p>
        <p class="md:max-w-[250px] text-hurrey-12 md:text-hurrey-16 text-ink_light">Explore joint initiatives that
          leverage the strengths of both parties for mutual business growth.</p>
      </div>
      <div class="col-span-2 md:col-span-1 lg:col-start-2 flex flex-col gap-1">
        <i-tabler name="brand-teams" class="custom-icon text-hurrey-16 md:text-hurrey-24"
          id="training_and_skill_development"></i-tabler>
        <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20">Training and Skill Development</p>
        <p class="md:max-w-[250px] text-shurrey-16 text-ink_light">Receive training and support to equip your team with
          the skills needed for successful integration.</p>
      </div>
    </div>
    <div class="content_image md:self-end">
      <img src="../../../../assets/images/atomo-for-providers.webp" alt="smart-class-providers">
    </div>
  </div>
</ng-template>