<blog-decor></blog-decor>

@if(!blogsLoading){
  @if (allBlogs.length!=0) {
    <div class="grid grid-cols-12 gap-3 p-4 justify-items-stretch">
      @for (blog of allBlogs; track $index) {
        <div class="col-span-12 md:col-span-6 lg:col-span-4 ">
          <blog-card [blog]="blog" (click)="navigateToBlog(blog)"></blog-card>
        </div>
      }
    </div>
  }@else {
    <div class="flex flex-col justify-center items-center my-3">
      <img src="../../../../assets/images/no_data.svg" alt="No Blogs" height="300" width="300">
      <p>No Blogs Found !!</p>
    </div>
  }
}@else {
 <h-spinner></h-spinner>
}

