export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyAqeVizpXEP87vmuMavH_Xz1AiuFo-QG7Q',
    authDomain: 'hurreytech-b72f1.firebaseapp.com',
    projectId: 'hurreytech-b72f1',
    storageBucket: 'hurreytech-b72f1.appspot.com',
    messagingSenderId: '866357892195',
    appId: '1:866357892195:web:81183643feec206488d456',
    measurementId: 'G-KTDDXQEMD7',
  },
};
