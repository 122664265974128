import { Component } from '@angular/core';
import { MathSolutionComponent } from '../../math-solver/math-solution/math-solution.component';
import { MathSolverFeaturesComponent } from '../../math-solver/math-solver-features/math-solver-features.component';
import { BuildLearningJourneyComponent } from '../../math-solver/build-learning-journey/build-learning-journey.component';
import { ScienceBehindMathSolverComponent } from '../../math-solver/science-behind-math-solver/science-behind-math-solver.component';
import { MathSolverProfessionalsComponent } from '../../math-solver/math-solver-professionals/math-solver-professionals.component';
import { ExploreMoreToolMathSolverComponent } from '../../math-solver/explore-more-tool-math-solver/explore-more-tool-math-solver.component';

@Component({
  selector: 'app-math-solver',
  standalone: true,
  imports: [MathSolutionComponent, MathSolverFeaturesComponent, BuildLearningJourneyComponent, ScienceBehindMathSolverComponent, MathSolverProfessionalsComponent, ExploreMoreToolMathSolverComponent],
  templateUrl: './math-solver.component.html',
  styleUrl: './math-solver.component.scss'
})
export class MathSolverComponent {

}
