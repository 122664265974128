<section #repository class="container" id="atomo-tools-container">
  <div [@slideInOut]
    class="flex flex-col items-start justify-start md:items-center md:justify-center py-24 md:py-14 gap-10">
    <p class="text-purple_100 text-hurrey-28 lg:text-hurrey-36 pl-4" id="atomo-tools-statement">One Repository, Unified
      Experience</p>

    <div class="grid grid-cols-2 gap-10 md:gap-20">
      <div class="col-span-2 md:col-span-1 order-first md:order-last">
        <div class="flex items-start justify-start md:items-end md:justify-end">
          <img src="../../../../assets/images/indivisula-ai_device_laptop.webp" alt="indivisual_ai_device" width="776"
            height="448">
        </div>

      </div>

      <div
        class="col-span-2 md:col-span-1 pl-4 md:pl-24 items-start justify-start md:items-center md:justify-center   ">
        <div class="flex flex-col  gap-10 pt-8 lg:pt-20 ">
          <div class="flex flex-col gap-4">
            <p class=" text-hurrey-24 lg:text-hurrey-36" [id]="'atomo_tool'+items[this.currentIndex]">{{
              items[this.currentIndex].title }}</p>
            <p class="text-hurrey-16 lg:text-hurrey-20"> Our Unified Ecosystem </p>
            <div class="flex gap-2">
              <div class="line" *ngFor="let item of items; let i = index" [class.active]="i === this.currentIndex"
                (click)="moveToCurrentIndex(i)" id="nav_slider">
              </div>
            </div>


          </div>
          <button class="explore_button text-hurrey-16 text-black" id="explore-button"
            routerLink="/products">Explore</button>
        </div>

      </div>



    </div>
  </div>