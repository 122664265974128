<section class=" py-28 md:py-14">
  <div class=" flex flex-col items-center justify-center gap-10 lg:gap-16">
    <p class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36" id="atomo_featured_on">As <span
        class="font-bold">Featured </span> On</p>


    <div class="logos w-[80%]">
      <div class="logos-slide">
        <img *ngFor="let photo of images" [src]="photo.imgUrl" [alt]="photo.alt">
      </div>

      <div class="logos-slide">
        <img *ngFor="let photo of images" [src]="photo.imgUrl" [alt]="photo.alt">
      </div>

      <div class="logos-slide">
        <img *ngFor="let photo of images" [src]="photo.imgUrl" [alt]="photo.alt">
      </div>
    </div>
  </div>
</section>