<section class="elevating_education_container h-screen" id="atomo-products-main-container">
  <div
    class="flex flex-col items-start justify-start gap-14 md:gap-11 md:items-center md:justify-center px-4 py-14 md:py-32 lg:pt-44 ">
    <p id="atomo-products-description"><span
        class="text-grey text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-48">Elevating Education with </span> <span
        class="text-purple_100 text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-48">AI <br class="block md:hidden">
        Solutions</span> </p>

    <img src="../../../../assets/images/elevating-education-group-image.webp" alt="elevating_education_group_image"
      class="group_image_container">

  </div>

</section>