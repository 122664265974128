<section id="our_vision_container" class="our_vision_container">
  <div [@slideInOut]
    class=" flex flex-col items-start justify-start gap-6 py-32 md:py-24 lg:py-48 px-4 md:pl-14 lg:pl-[108px]">
    <p class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36 text-purple_100" id="our-vision-head"> Our Vision</p>
    <p class="text-hurrey-16 text-black break-words" id="atomo_s_vision"> Our vision propels us towards limitless
      understanding, <br class="hidden md:block lg:hidden"> where
      knowledge is <br class="hidden lg:block">
      accessible, deep, and evolves alongside <br class="hidden md:block lg:hidden">
      individuals. Grounded in transparency, we <br class="hidden lg:block"> empower informed <br
        class="hidden md:block lg:hidden"> decisions, nurturing curiosity and innovation for a
      future <br class="hidden md:block lg:hidden">
      where <br class="hidden lg:block"> understanding shapes destinies. Together, we create <br
        class="hidden md:block lg:hidden"> endless possibilities,<br class="hidden lg:block">one insight
      at a
      time.</p>


  </div>
</section>