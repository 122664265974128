import { NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import {
  fadeInLeftOnEnterAnimation,
  fadeInRightOnEnterAnimation,
  fadeOutLeftOnLeaveAnimation,
  fadeOutRightOnLeaveAnimation,
} from 'angular-animations';
import { IconsModule } from '../../icons/icons.module';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'atomos-usp',
  standalone: true,
  imports: [NgOptimizedImage, NgIf, NgClass, IconsModule, RouterLink],
  templateUrl: './atomos-usp.component.html',
  styleUrl: './atomos-usp.component.scss',
  animations: [
    fadeInLeftOnEnterAnimation(),
    fadeInRightOnEnterAnimation(),
    fadeOutLeftOnLeaveAnimation(),
    fadeOutRightOnLeaveAnimation(),
  ],
})
export class AtomosUspComponent {
  bgArray: string[] = ['#FFB600', '#307FE2', '#000000', '#6b4eff'];

  selectedIndex: number = 0;

  direction: 'ltr' | 'rtl' = 'ltr';

  navigateBetweenSlides(dir: 1 | -1) {
    if (dir == -1) {
      this.direction = 'ltr';
      if (this.selectedIndex == 0) {
        return;
      } else {
        this.selectedIndex--;
      }
    } else {
      this.direction = 'rtl';
      if (this.selectedIndex == 3) {
        return;
      } else {
        this.selectedIndex++;
      }
    }
  }
}
