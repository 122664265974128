<footer id="atomo-footer" class="pt-[72px] md:pt-[68px] lg:pt-[86px] footer w-full">
  <div
    class="flex flex-col gap-[72px] md:gap-[46px] lg:gap-[105px] items-center justify-center px-4 md:px-14 lg:px-28  bottom_border pb-12 md:pb-6 lg:pb-14 "
    id="subscription-section">
    <!-- <div [@slideInOut] class="flex flex-col items-center justify-center w-full">
      <p class="text-center text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36 text-purple_100 mb-4"> It’s time to go
        live</p>
      <p class=" text-center text-grey text-hurrey-16 mb-8">Be the first to know real AI classroom is coming to your
        town.
      </p>
      <div class="Input-container">
        <input class=" text-hurrey-16 text-grey" type="text" id="subscription-email" placeholder="Enter your e-mail">
        <button class="subscribe text-white text-hurrey-12 md:text-hurrey-16 " type="submit"
          id="subscribe-button">Subscribe </button>
      </div>
    </div> -->

    <div class="grid grid-cols-12 gap-8 md:gap-4 lg:gap-6 ">
      <div class="col-span-12 md:col-span-4 justify-center items-center">
        <div class="flex flex-col gap-4 lg:gap-8 md:gap-6 ">
          <img ngSrc="../../../assets/images/atomo-logo.webp" alt="atomo_logo_with_name" width="100" height="36"
            priority class="cursor-pointer">

          <div class="flex justify-center items-center">
            <p class="text-grey text-hurrey-16 md:text-hurrey-12 lg:text-hurrey-16" id="atomo_goal">Atomo offers
              innovative personalized learning by understanding each student for tailored attention. Enhance your
              child's educational experience with Atomo's precision.</p>
          </div>

          <img ngSrc="../../../assets/images/google-play.webp" width="134" height="40"
            alt="Download_from_Google_Play_Store" class="cursor-pointer object-contain" (click)="openPlayStore()">

        </div>
      </div>

      <div class="col-span-12 md:col-span-4 justify-center items-center">
        <div class="flex flex-col gap-4 lg:gap-8 md:gap-6 ">
          <p class="text-black text-hurrey-16 md:text-hurrey-20 lg:text-hurrey-36"> Our Location</p>
          <div class="flex flex-col gap-2 md:gap-4 lg:gap-6">
            <div class="flex gap-1 md:gap-2 cursor-pointer">
              <img src="../../../assets/icons/location_icon.webp" class="object-contain items-center"
                alt="location_icon" width="16" height="16" priority>
              <address class="text-grey text-hurrey-16 md:text-hurrey-12 lg:text-hurrey-16">71-75, Shelton
                Street,
                Covent
                Garden, London, WC2H 9JQ, United Kingdom</address>
            </div>

            <div class="flex gap-1 md:gap-2 cursor-pointer ">
              <img src="../../../assets/icons/location_icon.webp" class="object-contain items-center"
                alt="location_icon" width="16" height="16">
              <address class="text-grey text-hurrey-16 md:text-hurrey-12 lg:text-hurrey-16 ">503, Brigade IRV
                Center,
                Whitefield, Bengaluru, Karnataka, 560066, INDIA</address>
            </div>
          </div>

        </div>

      </div>



      <div class="col-span-12 md:col-span-4 justify-center items-center">

        <div class="flex flex-col gap-4 md:gap-6 lg:gap-8  ">
          <p class="text-black text-hurrey-16 md:text-hurrey-20 lg:text-hurrey-36 ">Contact us</p>
          <div class="flex flex-col gap-2 md:gap-4 lg:gap-2">
            <div class="flex gap-1 lg:gap-2 cursor-pointer ">
              <img src="../../../assets/icons/call-icon.webp" alt="call_icon" class="object-contain " width="16"
                height="16">
              <a href="tel:+91 8210 403 625" class="text-hurrey-16 text-grey "> +91 82104 03625</a>
            </div>
            <div class="flex gap-1 lg:gap-2 cursor-pointer">
              <img src="../../../assets/icons/email-icon.webp" alt="email-icon" class="object-contain " width="16"
                height="16">
              <a href="mailto:info@hurreytech.com" class="text-hurrey-16 text-grey">info&#64;hurrey.ai</a>
            </div>
          </div>
          <div class="hidden md:block ">
            <p class="text-grey text-hurrey-16 md:text-hurrey-12 lg:text-hurrey-16 ">Hurrey recognized as Top 5 ‘Most
              Innovative Startups’ by ET Innovation Awards 2020.</p>
          </div>
        </div>

      </div>

    </div>

  </div>

  <div class="px-10 md:px-12 lg:px-24 gap-4 py-3 md:py-3 lg:py-6">
    <div class="flex items-center justify-between">
      <div class="flex items-center justify-center gap-24 md:gap-16">
        <p class="text-hurrey-12 text-grey  text-center">Copyright &#169; 2024-2025, Hurrey Tech Ventures Pvt. Ltd.</p>
        <a class="text-hurrey-12 text-grey text-center hidden md:block cursor-pointer underline" href="/privacy-policy"> Privacy Policy</a>
        <a class="text-hurrey-12 text-grey hidden md:block cursor-pointer underline" href="/terms-and-conditions">Terms of Use</a>
      </div>
      <div class="hidden md:block ">
        <img src="../../../assets/icons/linkdin_icon.webp" class="cursor-pointer" width="18" height="18"
          alt="linkdin_icon" (click)="navigateToLinkedIn()">
      </div>

    </div>
  </div>
</footer>
