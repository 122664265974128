import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'our-tech-stack',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './our-tech-stack.component.html',
  styleUrl: './our-tech-stack.component.scss',
})
export class OurTechStackComponent {
  private router: Router = inject(Router);

  tech_stack = [
    {
      title: 'Hurrey Scope',
      img: '../../../../assets/images/hurrey_scope.webp',
      description:
        'Guiding Your Academic Journey with Precision. By analyzing strengths, weaknesses, and predicting challenges, Hurreyscope provides personalized learning tailored specifically for you.',
      route: '/hurrey-scope',
    },
    {
      title: 'Language Learner',
      img: '../../../../assets/images/language_learner.webp',
      description:
        'Elevate language proficiency with Language Learner. Through meticulous analysis, it provides personalized insights and targeted improvement strategies.',
      route: '/language-learner',
    },
    {
      title: 'Physics Solver',
      img: '../../../../assets/images/physics_solver.webp',
      description:
        'Structured problem-solving from the ground up, with concise concept summaries and virtual lab implementation for practical learning.',
      route: '/physics-solver',
    },
    {
      title: 'Math Solver',
      img: '../../../../assets/images/math_solver.webp',
      description:
        'An advanced tutoring system for math and physics. It guides structured problem-solving and aids visualization for enhanced learning.',
      route: '/math-solver',
    },
    {
      title: 'Odyo',
      img: '../../../../assets/images/Odyo_1.webp',
      description:
        'Empower community podcasting for seamless recording and global sharing. Connect learners worldwide, pioneering accessible knowledge dissemination.',
      route: '',
    },
    {
      title: 'Experiment Center',
      img: '../../../../assets/images/experiment_center.webp',
      description:
        'Enable practical scientific exploration, inspiring passion and discovery. Enhance learning with interactive experiments, closing access gaps.',
      route: '',
    },

    { title: 'Apeiros Coming Soon' },
    { title: 'Meera Coming Soon' },
  ];

  goToProduct(route: string | undefined) {
    if (typeof route != undefined && route?.length != 0) {
      this.router.navigate([`${route}`]);
    } else {
      return;
    }
  }
}
