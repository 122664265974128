import { ApplicationRef, ComponentRef, createComponent, Directive, ElementRef, EmbeddedViewRef, EnvironmentInjector, HostListener, Input } from '@angular/core';
import { TooltipPosition } from '../models/tooltip-pos.enum';
import { HurreyTooltipComponent } from '../reusable-components/hurrey-tooltip/hurrey-tooltip.component';

@Directive({
  selector: '[hurreyTooltip]',
  standalone: true
})
export class HurreyTooltipDirective {
  @Input() tooltip = '';
  @Input() position: TooltipPosition = TooltipPosition.DEFAULT;
  @Input() showDelay = 0;
  @Input() hideDelay = 0;

  private componentRef!: ComponentRef<HurreyTooltipComponent> | null;
  private showTimeout?: number;
  private hideTimeout?: number;
  private touchTimeout?: number;

  constructor(
    private elementRef: ElementRef,
    private appRef: ApplicationRef,
    private injector: EnvironmentInjector
  ) {
    console.log(this.componentRef);
  }

  @HostListener('mouseenter')
  @HostListener('touchstart', ['$event'])
  onMouseEnter(): void {
    console.log('mouse entered');
    this.initializeTooltip();
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.setHideTooltipTimeout();
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove($event: MouseEvent): void {
    if (
      this.componentRef !== null &&
      this.position === TooltipPosition.DYNAMIC
    ) {
      this.componentRef.instance.left = $event.clientX;
      this.componentRef.instance.top = $event.clientY;
      this.componentRef.instance.tooltip = this.tooltip;
    }
  }

  private initializeTooltip() {
    if (!this.componentRef) {
      window.clearInterval(this.hideDelay);
      this.componentRef = createComponent(HurreyTooltipComponent, {
        environmentInjector: this.injector,
      });

      this.componentRef.instance.tooltip = this.tooltip;
      console.log(this.componentRef);

      this.appRef.attachView(this.componentRef.hostView);
      const [tooltipDOMElement] = (
        this.componentRef.hostView as EmbeddedViewRef<any>
      ).rootNodes;

      this.setTooltipComponentProperties();

      document.body.appendChild(tooltipDOMElement);
      this.showTimeout = window.setTimeout(
        this.showTooltip.bind(this),
        this.showDelay
      );
    }
  }

  private setTooltipComponentProperties() {
    if (this.componentRef !== null) {
      this.componentRef.instance.tooltip = this.tooltip;
      this.componentRef.instance.position = this.position;

      const { left, right, top, bottom } =
        this.elementRef.nativeElement.getBoundingClientRect();

      switch (this.position) {
        case TooltipPosition.BELOW: {
          this.componentRef.instance.left = Math.round(
            (right - left) / 2 + left
          );
          this.componentRef.instance.top = Math.round(bottom);
          break;
        }
        case TooltipPosition.ABOVE: {
          this.componentRef.instance.left = Math.round(
            (right - left) / 2 + left
          );
          this.componentRef.instance.top = Math.round(top);
          break;
        }
        case TooltipPosition.RIGHT: {
          this.componentRef.instance.left = Math.round(right);
          this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
          break;
        }
        case TooltipPosition.LEFT: {
          this.componentRef.instance.left = Math.round(left);
          this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  private showTooltip() {
    if (this.componentRef !== null) {
      this.componentRef.instance.visible = true;
    }
  }

  private setHideTooltipTimeout() {
    this.hideTimeout = window.setTimeout(
      this.destroy.bind(this),
      this.hideDelay
    );
  }

  destroy(): void {
    if (this.componentRef != null && this.componentRef.hostView != null) {
      window.clearInterval(this.showTimeout);
      window.clearInterval(this.hideDelay);
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }
}
