import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { slideInLeftOnEnterAnimation, slideInRightOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'our-genesis',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './our-genesis.component.html',
  styleUrl: './our-genesis.component.scss',
  animations: [
    slideInLeftOnEnterAnimation({ anchor: 'slideInLeft' }),
    slideInRightOnEnterAnimation({ anchor: 'slideInRight' })
  ]
})
export class OurGenesisComponent {

  @Input() currentStep: number = 2024;

}
