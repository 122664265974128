import { LanguageExpertiesComponent } from './../../language-learner-inner-pages/language-experties/language-experties.component';
import { Component } from '@angular/core';
import { LanguageLearnerFeaturesComponent } from '../../language-learner-inner-pages/language-learner-features/language-learner-features.component';
import { JourneyWithLanguageLearnerComponent } from '../../language-learner-inner-pages/journey-with-language-learner/journey-with-language-learner.component';
import { ScienceBehindLanguageLearnerComponent } from '../../language-learner-inner-pages/science-behind-language-learner/science-behind-language-learner.component';
import { ProfessionalsForLanguageLearnerComponent } from '../../language-learner-inner-pages/professionals-for-language-learner/professionals-for-language-learner.component';


@Component({
  selector: 'app-language-learner',
  standalone: true,
  imports: [LanguageExpertiesComponent, LanguageLearnerFeaturesComponent, JourneyWithLanguageLearnerComponent, ScienceBehindLanguageLearnerComponent, ProfessionalsForLanguageLearnerComponent],
  templateUrl: './language-learner.component.html',
  styleUrl: './language-learner.component.scss'
})
export class LanguageLearnerComponent {

}
