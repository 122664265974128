<section id="atomo_usps" class="h-screen slide px-4 md:px-14 lg:px-[108px] flex items-center relative"
  [ngClass]="{'bg-amber_100': selectedIndex==0,'bg-blue_100':selectedIndex==1,'bg-black':selectedIndex==2,'bg-purple_100':selectedIndex==3}">

  <ng-container *ngIf="selectedIndex == 0">
    <div class="flex flex-col gap-10" [@fadeInLeftOnEnter] id="usp-outer-wrap">
      <div class="flex flex-col gap-4 text-white" id="usp-inner-wrap">
        <p id="usp_title" class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-48">Individual is the key</p>
        <p class="text-hurrey-16" id="usp_description">Our approach emphasizes comprehensive <br
            class="block md:hidden">
          development by offering
          <br class="block lg:hidden"> individualized <br class="hidden lg:block"> attention to
          foster <br class="block md:hidden"> the unique
          potential <br class="hidden md:block lg:hidden"> and growth of <br class="block md:hidden"> every learner.
        </p>
      </div>
      <!-- <button id="explore" class="explore_button" routerLink="/use-cases">Explore</button> -->
    </div>
  </ng-container>


  <ng-container *ngIf="selectedIndex == 1">
    <div class="flex flex-col gap-10" [@fadeInLeftOnEnter] id="usp-outer-wrap">
      <div class="flex flex-col gap-4 text-white" id="usp-inner-wrap">
        <p class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-48" id="usp_title">Global Competitiveness</p>
        <p class="text-hurrey-16" id="usp_description">We facilitate global <br class="block md:hidden">
          competitiveness by <br class="hidden md:block lg:hidden">
          providing advanced <br class="block md:hidden"> resources,
          training, <br class="hidden lg:block"> and insights <br class="hidden md:block lg:hidden"> to enable <br
            class="block md:hidden"> businesses
          to excel internationally.</p>
      </div>
      <!-- <button class="explore_button" id="explore" routerLink="/use-cases">Explore</button> -->
    </div>
  </ng-container>



  <ng-container *ngIf="selectedIndex == 2">
    <div class="flex flex-col gap-10" [@fadeInLeftOnEnter] id="usp-outer-wrap">
      <div class="flex flex-col gap-4 text-white" id="usp-inner-wrap">
        <p class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-48" id="usp_title">Enhance Skill Development</p>
        <p class="text-hurrey-16" id="usp_description">Through Atomo, we emphasize the enhancement <br
            class="block md:hidden">of skill development,<br class="hidden md:block lg:hidden">
          empowering <br class="block md:hidden"> individuals to excel in
          <br class="hidden lg:block"> in <br class="hidden md:block lg:hidden">
          diverse <br class="block md:hidden"> professional and personal domains
        </p>
      </div>
      <!-- <button class="explore_button" id="explore" routerLink="/use-cases">Explore</button> -->
    </div>
  </ng-container>





  <ng-container *ngIf="selectedIndex == 3">
    <div class="flex flex-col gap-10" [@fadeInLeftOnEnter] id="usp-outer-wrap">
      <div class="flex flex-col gap-4 text-white" id="usp-inner-wrap">
        <p class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-48" id="usp_title">Data-Driven Decision Making</p>
        <p class="text-hurrey-16" id="usp_description">We leverage data to drive informed decisions, <br
            class="hidden md:block lg:hidden">
          helping organizations <br class="hidden lg:block"> optimize <br class="hidden md:block lg:hidden"> performance
          and achieve their
          strategic goals
          efficiently.</p>
      </div>
      <!-- <button class="explore_button" routerLink="/use-cases" id="explore">Explore</button> -->
    </div>
  </ng-container>



  <div class="flex gap-2 items-center nav_container">
    <i-tabler name="arrow-left" id="arrow-left" class="text-white" [ngClass]="{'opacity-50': selectedIndex==0}"
      (click)="navigateBetweenSlides(-1)"></i-tabler>
    <i-tabler name="arrow-right" id="arrow-right" class="text-white" [ngClass]="{'opacity-50': selectedIndex==3}"
      (click)="navigateBetweenSlides(1)"></i-tabler>
  </div>

</section>