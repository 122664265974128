<section class="number_which_matter_container w-full">
  <div
    class="flex flex-col gap-16 md:gap-14 lg:gap-28 items-center justify-center pt-20 md:pt-12 lg:pt-14 pb-32 md:pb-20 lg:pb-36">
    <p [@slideInLeft] class="text-white text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36"> Numbers that matter </p>
    <div [@slideInRight] class="flex flex-col gap-3 items-center justify-center">
      <p class="text-white"><span class="text-hurrey-28 md:text-hurrey-36 lg:text-hurrey-48">100K</span><span
          class="text-hurrey-12 md:text-hurrey-16 ">Numbers of Students</span><span
          class="text-hurrey-28 md:text-hurrey-36 lg:text-hurrey-48">75K</span>
      </p>
      <p class="text-white"><span class="text-hurrey-12 md:text-hurrey-16 ">Numbers of Teachers</span><span
          class="text-hurrey-28 md:text-hurrey-36 lg:text-hurrey-48">35K</span><span
          class="text-hurrey-12 md:text-hurrey-16 ">Numbers of Classrooms</span>
      </p>
      <p class="text-white"> <span class="text-hurrey-28 md:text-hurrey-36 lg:text-hurrey-48">1K</span> <span
          class="text-hurrey-12 md:text-hurrey-16 ">
          Numbers of Locations</span> <span class="text-hurrey-28 md:text-hurrey-36 lg:text-hurrey-48">250</span> <span
          class="text-hurrey-12 md:text-hurrey-16 ">Team members</span> </p>
    </div>
  </div>
</section>