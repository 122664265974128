<section class=" science_behind_solver_container flex flex-col gap-6 px-4  md:px-14 lg:px-[108px] py-20 lg:py-24"
  id="science_behind_solver_container">
  <p class="text-black font-bold text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36"> Science behind Math Solver</p>

  <div class="grid grid-cols-12">
    <p class="text-black text-hurrey-16 col-span-12 lg:col-span-6"> This technology detects and solves mathematical
      expressions, offering structured step-by-step solutions with detailed descriptions and visual representations when
      applicable. It personalizes user experiences by identifying errors and suggesting areas for improvement. Utilizing
      algorithms to classify expressions and apply appropriate solving methods, it enhances user proficiency in
      mathematics.
    </p>
  </div>

</section>