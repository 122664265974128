<div
  class="flex flex-col items-center justify-center px-4 md:px-14 lg:px-[108px]  pt-10 lg:pt-12 pb-20 md:pb-10 lg:pb-24 ">
  <p class="text-hurrey-24 text-rose_100 font-bold mb-3">Features</p>
  <p class="text-hurrey-24 lg:text-hurrey-36 text-black mb-14 font-bold"> Uncover our unique <br
      class="block md:hidden"> Language Learning tool
  </p>

  <div class="grid grid-cols-2 gap-6 md:gap-14 lg:gap-24 ">
    <div class="col-span-2 md:col-span-1 items-center ">
      <img src="../../../../assets/images/explore_words_and_contexts.webp" alt="comprehensive_learning">
    </div>
    <div class="col-span-2 md:col-span-1 flex flex-col gap-4 lg:gap-6 items-start justify-center mb-6 md:mb-0">
      <p class="text-black text-hurrey-24 lg:text-hurrey-28 font-bold"> Explore Words and Contexts</p>
      <p class="text-black text-hurrey-16"> Explore words thoroughly with detailed contextual insights, including
        synonyms, antonyms, and examples, for enhanced understanding and usage.</p>
    </div>

  </div>


  <div class="grid grid-cols-2 gap-6 md:gap-14 lg:gap-24 ">
    <div class="col-span-2 md:col-span-1 flex flex-col gap-4 lg:gap-6 items-start justify-center ">
      <p class="text-black text-hurrey-24 lg:text-hurrey-28 font-bold"> Category Based Learning</p>
      <p class="text-black text-hurrey-16"> Engage in an in-depth exploration of vocabulary related to specific topics
        or themes, facilitating targeted and contextualized language acquisition tailored to your particular interests
        and requirements.</p>
    </div>
    <div class="col-span-2 md:col-span-1 items-center order-first md:order-last mb-6 md:mb-0 ">
      <img src="../../../../assets/images/category_based_learning.webp" alt="get_structured_guidance">
    </div>
  </div>

  <div class="grid grid-cols-2 gap-6 md:gap-14 lg:gap-24 ">
    <div class="col-span-2 md:col-span-1 items-center ">
      <img src="../../../../assets/images/level_based_learning.webp" alt="refine_your_skills">
    </div>
    <div class="col-span-2 md:col-span-1 flex flex-col gap-4 lg:gap-6 items-start justify-center mb-6 md:mb-0">
      <p class="text-black text-hurrey-24 lg:text-hurrey-28 font-bold">Level Based Learning</p>
      <p class="text-black text-hurrey-16"> Facilitates a structured progression, leading you through progressively
        complex vocabulary levels to ensure consistent and sustained skill development.</p>
    </div>

  </div>

  <div class="grid grid-cols-2 gap-6 md:gap-14 lg:gap-24 mb-6 md:mb-16 ">
    <div class="col-span-2 md:col-span-1 flex flex-col gap-4 lg:gap-6 items-start justify-center ">
      <p class="text-black text-hurrey-24 lg:text-hurrey-28 font-bold">Arrange by Familiarity and Complexity</p>
      <p class="text-black text-hurrey-16"> Optimizes your vocabulary acquisition process by organizing words into
        manageable categories, ensuring a balanced and efficient learning experience.</p>
    </div>
    <div class="col-span-2 md:col-span-1 items-center order-first md:order-last  ">
      <img src="../../../../assets/images/familiarity_and_complexity.webp" alt="virtual_lab">
    </div>
  </div>

  <div class="flex flex-col items-center justify-between gap-12 md:gap-7 lg:gap-8 ">
    <p class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36 text-black font-bold"> Navigate learning with our
      companions</p>
    <div class="grid grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-6 ">
      <div *ngFor="let item of companions , let i = index ">
        <div class="col-span-1 md:col-span-1 flex gap-2 companions_card" [ngStyle]="{
      'background-color':
        i == 0 ? '#6B4EFF' :
        i == 1 ? '#307FE2' :
        i == 2 ? '#FFB600' :
        i == 3 ? '#E31C79' :
        '#6B4EFF'
    }">
          <img [src]="item.img">
          <p class="text-white text-hurrey-20 md:text-hurrey-24"> {{item.title}} </p>
        </div>
      </div>

    </div>

  </div>



</div>