import { Component } from '@angular/core';
import { slideInUpOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'our-vision',
  standalone: true,
  imports: [],
  templateUrl: './our-vision.component.html',
  styleUrl: './our-vision.component.scss',
  animations: [
    slideInUpOnEnterAnimation({ anchor: 'slideInOut' }),
  ]
})
export class OurVisionComponent {

}
