<section class=" science_behind_solver_container flex flex-col gap-6 px-4  md:px-14 lg:px-[108px] py-20 lg:py-24"
  id="science_behind_scope_container">
  <p class="text-black font-bold text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36"> Science behind Hurrey Scope</p>
  <div class="grid grid-cols-12">
    <p class="text-black text-hurrey-16 col-span-12 lg:col-span-6"> Hurreyscope combines data analytics, machine
      learning, and educational psychology to enhance student academic success. It assesses students' conceptual
      understanding and
      identifies strengths and weaknesses using advanced algorithms. By leveraging a sophisticated knowledge matrix, it
      predicts future proficiency and anticipates learning needs.
      It forecasts how students' competencies will impact future learning paths and enables proactive interventions to
      optimize academic trajectories. Ultimately,
      Hurreyscope provides students and educators with actionable insights for effective navigation of the educational
      landscape.
    </p>
  </div>

</section>