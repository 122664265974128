import { Component } from '@angular/core';

@Component({
  selector: 'science-behind-math-solver',
  standalone: true,
  imports: [],
  templateUrl: './science-behind-math-solver.component.html',
  styleUrl: './science-behind-math-solver.component.scss'
})
export class ScienceBehindMathSolverComponent {

}
