import { Component } from '@angular/core';

@Component({
  selector: 'math-solver-features',
  standalone: true,
  imports: [],
  templateUrl: './math-solver-features.component.html',
  styleUrl: './math-solver-features.component.scss'
})
export class MathSolverFeaturesComponent {

}
