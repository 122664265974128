<section id="atomo-about-container"
  class="h-screen atomo_about_container flex  items-center px-4 md:px-14 lg:px-[108px]">

  <div [@slideInOut] class="flex flex-col gap-4 md:gap-6">
    <p class="text-white text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-48"> About Atomo</p>
    <p class="text-white text-hurrey-16 break-words"> Atomo, Hurrey's leading educational AI solution, embodies
      <br class="hidden md:block lg:hidden"> personalized
      learning experiences.<br class="hidden md:hidden lg:block"> Named after the Latin
      word <br class="hidden md:block lg:hidden"> "Atomo," meaning "individual," it signifies Hurrey's <br
        class="hidden md:block lg:hidden"> dedication to <br class="hidden md:hidden lg:block"> understanding
      each learner. Through
      tailored <br class="hidden md:block lg:hidden">
      recommendations, continuous feedback, and <br class="hidden md:hidden lg:block"> assessments, Atomo <br
        class="hidden md:block lg:hidden"> customizes education for
      every student. This
      innovative <br class="hidden md:block lg:hidden">
      approach transforms <br class="hidden md:hidden lg:block"> learning, empowering students to <br
        class="hidden md:block lg:hidden"> follow their unique paths to knowledge and growth.
    </p>


  </div>

</section>