import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  slideInDownOnEnterAnimation,
  slideInRightOnEnterAnimation,
} from 'angular-animations';
import {
  BenificiaryContent,
  BenificiarySubContent,
} from '../../../shared/models/benificiary-content.model';

@Component({
  selector: 'beneficiaries-partners',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './beneficiaries-partners.component.html',
  styleUrl: './beneficiaries-partners.component.scss',
  animations: [
    // slideInRightOnEnterAnimation({ anchor: 'slideInRight' }),
    trigger('slideInRight', [
      state('void', style({ transform: 'translateX(100%)' })),
      state('visible', style({ transform: 'translateX(0)' })),
      transition('void => visible', animate('0.5s ease-in-out')),
      transition('visible => void', animate('0.5s ease-in-out')),
    ]),
    trigger('slideInDown', [
      state('void', style({ transform: 'translateY(-100%)' })),
      state('visible', style({ transform: 'translateY(0)' })),
      transition('void => visible', animate('0.5s ease-in-out')),
      transition('visible => void', animate('0.5s ease-in-out')),
    ]),
  ],
})
export class BeneficiariesPartnersComponent {
  beneficiaresContents: BenificiaryContent[] = [
    {
      content: 'Schools',
      bgColor: '#307fe2',
      class: 'schools',
      image: '../../../../assets/icons/school_icon.svg',
    },
    {
      content: 'Non-Governmental Organizations',
      bgColor: '#e31c79',
      class: 'ngo',
      image: '../../../../assets/icons/non-govt_office_icon.svg',
    },
    {
      content: 'Government & Administration',
      bgColor: '#ffb600',
      class: 'ga',
      image: '../../../../assets/icons/govt_office_icon.svg',
    },
    {
      content: 'Learn More',
      bgColor: '#6b4eff',
      class: 'learn_more',
      image: '../../../../assets/icons/right_arrow_white.svg',
      hoverImage: '../../../../assets/icons/right_arrow_purple.svg',
    },
    {
      content: 'CSR Funders',
      bgColor: '#6b4eff',
      class: 'csr',
      image: '../../../../assets/icons/csr_funders_icon.svg',
    },
    {
      content: [
        { text: 'Teachers', bgColor: 'blue' },
        { text: 'Students', bgColor: 'pink' },
        { text: 'Parents', bgColor: 'purple' },
        { text: 'Publishers', bgColor: 'yellow' },
      ],
      bgColor: '',
      class: 'stakeHolders',
    },
  ];

  returnSubContent(ben: BenificiaryContent) {
    return ben.content as BenificiarySubContent[];
  }
}
