import { Component } from '@angular/core';
import { AtomosHeaderComponent } from '../atomos-header/atomos-header.component';
import { AtomosFooterComponent } from '../atomos-footer/atomos-footer.component';
import { OurPartnerFoundriesComponent } from '../../modules/landing/our-partner-foundries/our-partner-foundries.component';
import { TrustedByCustomersComponent } from '../../modules/landing/trusted-by-customers/trusted-by-customers.component';
import { NumberWhichMatterComponent } from '../../modules/landing/number-which-matter/number-which-matter.component';
import { FeaturedOnComponent } from '../../modules/landing/featured-on/featured-on.component';
import { OurMissionComponent } from '../../modules/landing/our-mission/our-mission.component';
import { OurInnovationsComponent } from '../../modules/landing/our-innovations/our-innovations.component';
import { OneRepositoryUnifiedExpComponent } from '../../modules/landing/one-repository-unified-exp/one-repository-unified-exp.component';
import { BeneficiariesPartnersComponent } from '../../modules/landing/beneficiaries-partners/beneficiaries-partners.component';
import { AtomoAiComponent } from '../../modules/landing/atomo-ai/atomo-ai.component';
import { AiPoweredEducationalComponent } from '../../modules/landing/ai-powered-educational/ai-powered-educational.component';
import { AtomosUspComponent } from '../../modules/landing/atomos-usp/atomos-usp.component';
import { EducationalExcellanceComponent } from '../../modules/landing/educational-excellance/educational-excellance.component';
import { HowDoWeImplementComponent } from '../../modules/landing/how-do-we-implement/how-do-we-implement.component';
import { LandingComponent } from '../../modules/landing/landing/landing.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-atomos-layout',
  standalone: true,
  imports: [
    LandingComponent,
    AtomosHeaderComponent,
    AtomosFooterComponent,
    RouterOutlet,
  ],
  templateUrl: './atomos-layout.component.html',
  styleUrl: './atomos-layout.component.scss',
})
export class AtomosLayoutComponent {}
