<section class=" science_behind_solver_container flex flex-col gap-6 px-4  md:px-14 lg:px-[108px] py-20 lg:py-24">
  <p class="text-black font-bold text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36" id="science_behind_physics_solver">
    Science behind Physics Solver</p>
  <div class="grid grid-cols-12">
    <p class="text-black text-hurrey-16 col-span-12 lg:col-span-6"> The module includes an Experiment Center for
      uploading and learning from experiment videos, enhancing practical understanding. This technology uses AI
      algorithms to detect query topics, providing tailored solutions and precise answers. Through step-by-step
      breakdowns and visual aids, it improves user proficiency, fostering a deeper comprehension of physics.
    </p>
  </div>

</section>