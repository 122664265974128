import { Component, Input } from '@angular/core';
import { SeoBlog } from '../../../shared/models/seo-blog.model';
import { HurreyTooltipDirective } from '../../../shared/directives/hurrey-tooltip.directive';
import { TooltipPosition } from '../../../shared/models/tooltip-pos.enum';

@Component({
  selector: 'blog-card',
  standalone: true,
  imports: [HurreyTooltipDirective],
  templateUrl: './blog-card.component.html',
  styleUrl: './blog-card.component.scss'
})
export class BlogCardComponent {

  postion:TooltipPosition = TooltipPosition.DYNAMIC

  @Input() blog!:SeoBlog

}
