<div class="black_div"></div>


<div class="content_wrapper py-4">
  <div class="content">

    <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20 my-2 md:my-4 lg:my-6">**Terms and Conditions**</p>

    <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20 my-2 md:my-4 lg:my-6">**Effective Date:** 30 Aug 2024
    </p>

    <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20 my-2 md:my-4 lg:my-6">Welcome to Atomo. By accessing or
      using our website, www.theatomo.com (“Site”),
      you agree to comply with and be bound by the following terms and conditions (“Terms”).
      Please read these Terms carefully before using the Site.</p>

    <div class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20 my-2 md:my-4 lg:my-6 flex flex-col gap-3">
      <p>### 1. **Acceptance of Terms**</p>
      <p>By accessing or using the Site, you acknowledge that you have read, understood, and
        agree to be bound by these Terms. If you do not agree with any part of these Terms,
        please do not use the Site.</p>
    </div>

    <div class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20 my-2 md:my-4 lg:my-6 flex flex-col gap-3">
      <p>### 2. **Modifications to Terms**</p>
      <p>We reserve the right to modify these Terms at any time without prior notice. Your
        continued use of the Site after any such changes constitutes your acceptance of the
        new Terms. Please check this page regularly for updates.</p>
    </div>

    <div class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20 my-2 md:my-4 lg:my-6 flex flex-col gap-3">
      <p>### 3. **Use of the Site**</p>
      <p>You agree to use the Site only for lawful purposes and in a manner that does not infringe
        the rights of, restrict, or inhibit anyone else’s use of the Site. You are prohibited from
        using the Site to transmit or post any harmful, offensive, or illegal content.</p>
    </div>

    <div class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20 my-2 md:my-4 lg:my-6 flex flex-col gap-3">
      <p>### 4. **Intellectual Property**</p>
      <p>All content on the Site, including text, graphics, logos, images, and software, is the
        property of Atomo or its content suppliers and is protected by intellectual property laws.
        You may not reproduce, distribute, or otherwise use any content from the Site without
        our express written permission.</p>
    </div>


    <div class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20 my-2 md:my-4 lg:my-6 flex flex-col gap-3">
      <p>### 5. **Privacy Policy**</p>
      <p>Your use of the Site is also governed by our Privacy Policy, which can be found at <a
          class="cursor-pointer underline" href="/privacy-policy">Privacy and Policy</a>. By using the Site, you consent
        to the practices described in the
        Privacy Policy.</p>
    </div>


    <div class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20 my-2 md:my-4 lg:my-6 flex flex-col gap-3">
      <p>### 6. **User Accounts**</p>
      <p>If you create an account on the Site, you are responsible for maintaining the
        confidentiality of your account information and password. You agree to accept
        responsibility for all activities that occur under your account.</p>
    </div>

    <div class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20 my-2 md:my-4 lg:my-6 flex flex-col gap-3">
      <p>### 7. **Third-Party Links**</p>
      <p>The Site may contain links to third-party websites that are not owned or controlled by
        Atomo. We are not responsible for the content, privacy policies, or practices of any
        third-party websites. Accessing third-party sites is at your own risk.</p>
    </div>


    <div class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20 my-2 md:my-4 lg:my-6 flex flex-col gap-3">
      <p>### 8. **Limitation of Liability**</p>
      <p>Atomo shall not be liable for any damages arising from your use of the Site, including
        but not limited to direct, indirect, incidental, punitive, and consequential damages. The
        Site is provided on an “as is” and “as available” basis without warranties of any kind.</p>
    </div>


    <div class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20 my-2 md:my-4 lg:my-6 flex flex-col gap-3">
      <p>### 9. **Governing Law**</p>
      <p>These Terms are governed by and construed in accordance with the laws of India. Any
        disputes arising out of or relating to these Terms shall be resolved in the courts of Maharashtra.</p>
    </div>


    <div class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20 my-2 md:my-4 lg:my-6 flex flex-col gap-3">
      <p>### 10. **Contact Information**</p>
      <p>If you have any questions about these Terms, please contact us.</p>
    </div>

    <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20 my-2 md:my-4 lg:my-6">By using our Site, you agree to
      these Terms and Conditions. Thank you for visiting
      <a class="cursor-pointer underline" href="http://www.theatomo.com">www.theatomo.com</a>.</p>

  </div>
</div>
