import { Component } from '@angular/core';
import { slideInLeftOnEnterAnimation, slideInRightOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'who-we-are',
  standalone: true,
  imports: [],
  templateUrl: './who-we-are.component.html',
  styleUrl: './who-we-are.component.scss',
  animations: [
    slideInLeftOnEnterAnimation({ anchor: 'slideInLeft' }),
    slideInRightOnEnterAnimation({ anchor: 'slideInRight' })
  ]
})
export class WhoWeAreComponent {

}
