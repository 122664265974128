<section id="api-integration_container"
  class="api-integration_container  flex flex-col items-center  py-10 lg:py-20 gap-6 lg:gap-8 px-4 md:px-16 lg:px-[120px]">
  <p class="text-white  text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36 " id="atomo_api_integration">API Integration
  </p>
  <div class="text-white text-hurrey-16 md:text-hurrey-20 text-center flex flex-col gap-5 ">
    <p id="atomo_api_integration_description"> Effortlessly integrate Atomo's powerful AI <br class="block md:hidden">
      capabilities into
      your existing <br class="hidden lg:hidden md:block"> system with our <br class="block md:hidden lg:block">
      seamless
      API
      Integration.</p>

  </div>

  <div class="grid grid-cols-2 md:grid-cols-6 gap-x-4 gap-y-6 lg:gap-x-16 lg:gap-y-8 ">
    <div class="col-span-1 md:col-span-2 flex items-start justify-start  api-integration_chips ">
      <p id="atomo_contextual_ai">Contextual AI</p>

    </div>
    <div class="col-span-1 md:col-span-2 flex items-start justify-start  api-integration_chips">
      <p id="atomo_math_solver">Math Solver/Practice</p>

    </div>
    <div class="col-span-1 md:col-span-2  flex items-start justify-start api-integration_chips">
      <p id="atomo_assessment_ocr">Assessment OCR</p>

    </div>
    <div class="col-span-1 md:col-span-2 md:col-start-2  api-integration_chips">
      <p id="atomo_business_potential">Business Potential</p>

    </div>
    <div class="col-span-1 md:col-span-2  md:col-start-4 api-integration_chips">
      <p id="atomo_face_recognition">Face Recognition</p>

    </div>
    <div class="col-span-1 md:col-span-2 flex items-start justify-start api-integration_chips">
      <p id="atomo_language_learner">Language Learner</p>

    </div>
    <div class="col-span-1 md:col-span-2 flex items-start justify-start api-integration_chips">
      <p id="atomo_recommendation_engine">Recommendation Engine</p>

    </div>
    <div class="col-span-1 md:col-span-2 flex items-start justify-start api-integration_chips">
      <p id="atomo_physics_solver">Physics Solver</p>

    </div>


  </div>



</section>