import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { atomoEducation } from '../../../shared/models/atomo-education.model';

@Component({
  selector: 'atomo-education-os',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './atomo-education-os.component.html',
  styleUrl: './atomo-education-os.component.scss'
})
export class AtomoEducationOsComponent {


  atomo_education: atomoEducation[] = [

    { img: '../../../../assets/images/holistic_education_system.svg', title: 'Holistic Education Ecosystem', description: 'Atomo blends tech, AI, and pedagogical expertise for elevated learning.' },
    { img: '../../../../assets/images/adaptability_across_schools.svg', title: 'Adaptability Across Schools', description: 'Seamlessly integrates with various educational setups for inclusive evolution' },
    { img: '../../../../assets/images/surpassing_lms_standard.svg', title: 'Surpassing LMS Standards', description: 'Empowers educators beyond typical LMS capabilities.' },
    { img: '../../../../assets/images/free_internet_capability.svg', title: 'Internet-free Capability', description: 'Operates without constant internet, ensuring learning continuity in low-connectivity areas.' },
    { img: '../../../../assets/images/user-friendly_interface.svg', title: 'User-Friendly Interface', description: 'Intuitive interface for ease of use by educators, students, and administrators.' },
    { img: '../../../../assets/images/eos.svg', title: 'eOS: Education Operating System', description: 'Comprehensive platform housing educational tools and resources.' },
    { img: '../../../../assets/images/personalized_ai_learning.svg', title: 'Personalized Learning with AI', description: 'Personalized student knowledge indices for tailored learning and teaching.' },
    { img: '../../../../assets/images/portability.svg', title: 'Portability', description: 'Easily transportable across different learning environments.' }



  ]

}
