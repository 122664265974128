import { Component } from '@angular/core';

@Component({
  selector: 'atomos-super-app',
  standalone: true,
  imports: [],
  templateUrl: './atomos-super-app.component.html',
  styleUrl: './atomos-super-app.component.scss'
})
export class AtomosSuperAppComponent {

}
