import { Component } from '@angular/core';

@Component({
  selector: 'api-integration',
  standalone: true,
  imports: [],
  templateUrl: './api-integration.component.html',
  styleUrl: './api-integration.component.scss'
})
export class ApiIntegrationComponent {

}
