import { Component } from '@angular/core';
import { slideInLeftOnEnterAnimation, slideInRightOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'number-which-matter',
  standalone: true,
  imports: [],
  templateUrl: './number-which-matter.component.html',
  styleUrl: './number-which-matter.component.scss',
  animations: [
    slideInLeftOnEnterAnimation({ anchor: 'slideInLeft' }),
    slideInRightOnEnterAnimation({ anchor: 'slideInRight' })
  ]

})
export class NumberWhichMatterComponent {

}
