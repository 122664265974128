import { Component } from '@angular/core';
import { AnimationOptions, LottieComponent, LottieDirective } from 'ngx-lottie';

@Component({
  selector: 'educational-excellance',
  standalone: true,
  imports: [LottieDirective, LottieComponent],
  templateUrl: './educational-excellance.component.html',
  styleUrl: './educational-excellance.component.scss',
})
export class EducationalExcellanceComponent {
  options: AnimationOptions = {
    path: '../../../../assets/media/Atom_01.json',
  };
}
