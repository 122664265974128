import { NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { slideInUpOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'atomos-footer',
  standalone: true,
  imports: [NgOptimizedImage],
  templateUrl: './atomos-footer.component.html',
  styleUrl: './atomos-footer.component.scss',
  animations: [slideInUpOnEnterAnimation({ anchor: 'slideInOut' })],
})
export class AtomosFooterComponent {
  openPlayStore() {
    window.open(
      'https://play.google.com/store/apps/details?id=com.hurrey.superapp',
      '_blank'
    );
  }

  navigateToLinkedIn(){
    window.open(
      'https://www.linkedin.com/company/hurrey',
      '_blank'
    );
    //
  }
}
