<div class="grid grid-cols-2 px-4 md:pl-12 gap-10 md:gap-12 lg:gap-36 py-32">

  <div [@slideInLeft] class="col-span-2 md:col-span-1 flex flex-col ">
    <div class="flex items-start justify-start mb-[-152px]">
      <p class="text-white text-[260px] items-start">“ </p>
    </div>
    <div class="flex items-center justify-center">
      <p class="text-white text-hurrey-36 font-bold"> Empowering minds,<br> shaping futures— <br>
        one learner at a time</p>
    </div>
    <div class="flex items-end justify-end mt-[-152px]">
      <p class="text-white text-[260px] rotate-180">“ </p>
    </div>
  </div>

  <div [@slideInRight] class="col-span-2 md:col-span-1 flex items-center justify-center order-first md:order-last">
    <div class="flex flex-col items-start justify-start gap-4 md:gap-6">
      <p class="text-white text-hurrey-36 "> Who We Are</p>
      <p class="text-white text-hurrey-16"> We are leaders in education, dedicated to excellence,<br
          class="hidden lg:block"> integrity, and
        inclusivity. Our mission is to empower<br class="hidden lg:block"> individuals
        through personalized, transformative learning <br class="hidden lg:block"> experiences, shaping a brighter
        future
        for all.</p>

    </div>

  </div>

</div>