import { Component } from '@angular/core';

@Component({
  selector: 'physics-excellence',
  standalone: true,
  imports: [],
  templateUrl: './physics-excellence.component.html',
  styleUrl: './physics-excellence.component.scss'
})
export class PhysicsExcellenceComponent {

}
