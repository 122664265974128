import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'featured-on',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './featured-on.component.html',
  styleUrl: './featured-on.component.scss',
})
export class FeaturedOnComponent {
  images = [
    {
      imgUrl: '../../../../assets/images/the_logical_indian_logo.png',
      alt: 'The logical indian logo',
    },
    {
      imgUrl: '../../../../assets/images/the_economytimes_logo.png',
      alt: 'The economy times logo',
    },
    {
      imgUrl: '../../../../assets/images/the_poineer_logo.png',
      alt: 'The poineer logo',
    },
    {
      imgUrl: '../../../../assets/images/the_sundaygurdian_logo.png',
      alt: 'The sunday guardian',
    },
  ];
}
