<section class="innovations_container">
  <div
    class="flex flex-col items-start justify-start md:items-center md:justify-center  px-4 md:px-28 lg:px-40  py-24 md:py-10 lg:py-24 gap-4 md:gap-6">
    <p [@slideInRight] class="text-purple_100 text-hurrey-28  lg:text-hurrey-36" id="our-innovations-title">Our
      Innovations</p>

    <div class="block md:hidden">
      <p [@slideInRight] class="text-hurrey-16 text-grey"> Our AI Innovation emphasizes the pivotal role of educators,
        rejecting AI's
        replacement narrative and advocating for
        collaborative integration in education</p>
    </div>


    <div class="hidden md:block">
      <div [@slideInLeft] class="flex gap-6">
        <div class="card" *ngFor="let item of innovations let i=index" [id]="'innovation_card'+i"
          [ngStyle]="{'background-color': item.backgroundColor}">
          <div class="">
            <div class="flex flex-col px-4 py-6 lg:py-8 lg:px-8 gap-2 lg:gap-4">
              <img [src]="item.img" [alt]="item.title" width="64" height="64">
              <p class="text-hurrey-16 lg:text-hurrey-24 text-white" [id]="'innovation'+item.title"> {{item.title}}</p>
              <p class="text-white text-hurrey-12 lg:text-hurrey-16" [id]="'innovation'+item.title+'description'">
                {{item.discription}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- this is for mobile -->

    <div [@slideInLeft] class="block md:hidden w-full">
      <div class="custom_container">
        <div class="flex gap-2 ">
          <div class="mobile_card" *ngFor="let item of innovations let i=index" [id]="'innovation_mobile_card'+i"
            [ngStyle]="{'background-color': item.backgroundColor}">
            <div class="">
              <div class="flex flex-col py-6 px-4">
                <img [src]="item.img" [alt]="item.title" width="48" height="48" class="mb-2">
                <p class="text-hurrey-20  text-white mb-2" [id]="'innovation'+item.title"> {{item.title}}</p>
                <p class="text-white text-hurrey-16 " [id]="'innovation'+item.title+'description'"> {{item.discription}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>


</section>