<section class="atomo_ai_container">

  <div class="flex flex-col items-start justify-start px-4 md:px-14 lg:px-28 py-36 md:py-44">
    <div class="text-style">
      <p class=" text-hurrey-36 lg:text-hurrey-48 " id="atomo-offerings">
        Atomo AI offers groundbreaking personalized learning by identifying obstacles and providing individualized
        attention.
      </p>
    </div>

  </div>

</section>