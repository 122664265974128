import {
  Timestamp,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from '@angular/fire/firestore';

export interface SeoBlog {
  title: string;
  meta_title: string;
  tags: string[];
  categories: string[];
  slug: string;
  keywords: string[];
  description: string;
  project: string;
  created_at: Date;
  modified_at: Date | null;
  body: string;
  creator: string;
  creator_uid: string;
  thumbnail: string;
  docId?: string;
}

export const SeoBlogConverter = {
  toFirestore: function (seoBlog: SeoBlog) {
    return {
      title: seoBlog.title,
      meta_title: seoBlog.meta_title,
      tags: seoBlog.tags,
      categories: seoBlog.categories,
      slug: seoBlog.slug,
      keywords: seoBlog.keywords,
      description: seoBlog.description,
      project: seoBlog.project,
      created_at: Timestamp.fromDate(seoBlog.created_at),
      modified_at: seoBlog.modified_at
        ? Timestamp.fromDate(seoBlog.modified_at)
        : null,
      body: seoBlog.body,
      creator: seoBlog.creator.toLowerCase(),
      creator_uid: seoBlog.creator_uid,
      thumbnail: seoBlog.thumbnail,
    };
  },
  fromFirestore: function (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ) {
    const data = snapshot.data(options);
    const seoBlog: SeoBlog = {
      title: data['title'],
      meta_title: data['meta_title'],
      tags: data['tags'],
      categories: data['categories'],
      slug: data['slug'],
      keywords: data['keywords'],
      description: data['description'],
      project: data['project'],
      created_at: data['created_at'].toDate(),
      modified_at: data['modified_at'] ? data['modified_at'].toDate() : null,
      body: data['body'],
      creator: data['creator'],
      creator_uid: data['creator_uid'],
      thumbnail: data['thumbnail'],
      docId: snapshot.id,
    };
    return seoBlog;
  },
};
