<section id="hurrey-scope-container" class="h-screen flex flex-col items-center gap-6  justify-center bg-black px-4 ">
  <p class="text-white text-hurrey-36 font-bold text-center">Unlock your academic potential <br
      class="hidden md:block lg:hidden">
    with <br class="hidden lg:block"> Hurrey Scope
  </p>

  <p class="text-white text-hurrey-16 text-center" id="hurrey-scope-intro"> Welcome to HurreyScope, where your academic
    journey transforms
    becomes a pathway to <br class="hidden md:block lg:hidden"> potential. Receive insights, guidance, and <br
      class="hidden lg:block">
    personalized support to unlock your educational <br class="hidden md:block lg:hidden"> success.
  </p>
</section>