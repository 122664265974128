import { Component } from '@angular/core';
import { AboutAtomoComponent } from '../../company-page/about-atomo/about-atomo.component';
import { WhoWeAreComponent } from '../../company-page/who-we-are/who-we-are.component';
import { OurVisionComponent } from '../../company-page/our-vision/our-vision.component';
import { OurGenesisComponent } from '../../company-page/our-genesis/our-genesis.component';
import { OurTeamComponent } from '../../company-page/our-team/our-team.component';

@Component({
  selector: 'app-company',
  standalone: true,
  imports: [
    AboutAtomoComponent,
    WhoWeAreComponent,
    OurVisionComponent,
    OurGenesisComponent,
    OurTeamComponent,
  ],
  templateUrl: './company.component.html',
  styleUrl: './company.component.scss',
})
export class CompanyComponent {}
