import { Component } from '@angular/core';

@Component({
  selector: 'physics-solver-features',
  standalone: true,
  imports: [],
  templateUrl: './physics-solver-features.component.html',
  styleUrl: './physics-solver-features.component.scss'
})
export class PhysicsSolverFeaturesComponent {

}
