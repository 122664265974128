import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'language-learner-features',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './language-learner-features.component.html',
  styleUrl: './language-learner-features.component.scss',
})
export class LanguageLearnerFeaturesComponent {
  companions = [
    {
      img: '../../../../assets/images/jumbled_letters.webp',
      title: 'Jumbled Letters',
    },
    { img: '../../../../assets/images/speech_pace.webp', title: 'Speech Pace' },
    { img: '../../../../assets/icons/Aa.svg', title: 'Word to Meaning' },
    {
      img: '../../../../assets/images/meaning_to_words.webp',
      title: 'Meaning to Words',
    },
  ];
}
