import { Component, inject, OnInit } from '@angular/core';
import {
  collection,
  collectionData,
  Firestore,
  query,
  where,
} from '@angular/fire/firestore';
import {
  SeoBlog,
  SeoBlogConverter,
} from '../../../shared/models/seo-blog.model';
import { take } from 'rxjs';
import { BlogCardComponent } from '../blog-card/blog-card.component';
import { BlogDecorComponent } from '../blog-decor/blog-decor.component';
import { SpinnerComponent } from '../../../shared/reusable-components/spinner/spinner.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blogs',
  standalone: true,
  imports: [BlogDecorComponent,BlogCardComponent,SpinnerComponent],
  templateUrl: './blogs.component.html',
  styleUrl: './blogs.component.scss',
})
export class BlogsComponent implements OnInit {
  private firestore: Firestore = inject(Firestore);

  allBlogs: SeoBlog[] = [];

  blogsLoading: boolean = false;

  private router:Router = inject(Router)

  ngOnInit(): void {
    this.blogsLoading = true;
    this.fetchBlogs().subscribe({
      next: (blogs: SeoBlog[]) => {
        this.blogsLoading = false;
        this.allBlogs = blogs;
      },
      error: (err) => {
        this.blogsLoading = false
        console.log(err);
      },
    });
  }

  fetchBlogs() {
    const blogsQuery = query(
      collection(this.firestore, `blogs`).withConverter(SeoBlogConverter),
      where('project', '==', 'atomo-site')
    );
    return collectionData(blogsQuery).pipe(take(1));
  }

  navigateToBlog(blog:SeoBlog){
    this.router.navigate([`blogs/${blog.slug}`])
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }
}
