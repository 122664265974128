<div
  class="h-screen language_experties_container flex flex-col items-center justify-center px-4 md:px-14 lg:px-[108px] ">
  <p class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-48 mb-6 text-center"> <span
      class="text-black font-bold">Shaping
      Individualized <br class="block md:hidden"> Language <br class="hidden lg:block">
      Expertise,</span> <br class="block lg:hidden">
    <span class="text-rose_100">in Unity </span>
  </p>
  <p class="text-hurrey-16 lg:text-hurrey-20 text-black mb-14 md:mb-10 lg:mb-14 text-center "> Revolutionize your
    language journey with
    our <br class="block md:hidden"> holistic platform, utilizing advanced <br class="hidden md:block lg:hidden">
    analytics to <br class="hidden md:hidden lg:hidden"> personalize content and <br class="hidden lg:block">
    accelerate proficiency <br class="hidden md:hidden lg:hidden"> advancement.
  </p>
  <!-- <button class="get_app_button"> Get App</button> -->
</div>