<section class="flex flex-col px-4 py-10 md:px-14 md:py-8 lg:py-14 lg:px-[108px] atomo_educations_container"
  id="atomo_educations_container">
  <p class="text-grey text-hurrey-16 mb-1 md:mb-2" id="atomo-features">FEATURES </p>
  <p class="text-purple_100 text-hurrey-24 lg:text-hurrey-36 mb-5 lg:mb-12" id="atomo-education_os"> Atomo Education OS
  </p>
  <div class="grid grid-cols-2  md:grid-cols-3 gap-x-4 gap-y-4 lg:gap-x-6 lg:gap-y-12">
    <ng-container *ngFor="let item of atomo_education;let i= index">
      <div class="col-span-1 md:col-span-1 flex ">
        <div class="flex flex-col gap-1 lg:gap-2 items-start">
          <img [src]="item.img" [alt]="item.title" class="icon">
          <p class="lg:text-hurrey-20 text-hurrey-12 text-black" [id]="'atomo-feature'+'-'+i"> {{item.title}}</p>
          <p class="text-grey text-hurrey-12 lg:text-hurrey-16 break-words" style="flex:1 0 0"
            [id]="'atomo-feature'+'-'+i+'-description'">{{item.description}}</p>
        </div>
      </div>
    </ng-container>
  </div>

</section>