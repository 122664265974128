import { Component } from '@angular/core';
import { ApiIntegrationComponent } from '../../atomo-products/api-integration/api-integration.component';
import { OurTechStackComponent } from '../../atomo-products/our-tech-stack/our-tech-stack.component';
import { AtomoEducationOsComponent } from '../../atomo-products/atomo-education-os/atomo-education-os.component';
import { KonnectDeviceHardwareComponent } from '../../atomo-products/konnect-device-hardware/konnect-device-hardware.component';
import { AtomosSuperAppComponent } from '../../atomo-products/atomos-super-app/atomos-super-app.component';
import { AtomosTechStackComponent } from '../../atomo-products/atomos-tech-stack/atomos-tech-stack.component';
import { ElevatingEducationWithAiComponent } from '../../atomo-products/elevating-education-with-ai/elevating-education-with-ai.component';

@Component({
  selector: 'app-atomo-products',
  standalone: true,
  imports: [ApiIntegrationComponent, OurTechStackComponent, AtomoEducationOsComponent, KonnectDeviceHardwareComponent, AtomosSuperAppComponent, AtomosTechStackComponent, ElevatingEducationWithAiComponent],
  templateUrl: './atomo-products.component.html',
  styleUrl: './atomo-products.component.scss'
})
export class AtomoProductsComponent {

}
