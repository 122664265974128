<section class=" intro_container  flex flex-col items-center justify-center pt-14 lg:pt-[108px] pb-6 md:pb-14">
  <p id="hurrey_scope_intro_msg" class="text-black intro_hurrey_scope text-center mb-6"> <span
      class=" flex font-bold text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36">Introducing
      Hurrey Scope</span> <br> <span class=" text-hurrey-20 lg:text-hurrey-20"> Your
      Key to Academic Success</span> </p>

  <p class="text-center text-black  text-hurrey-16 mb-10" id="hurrey-scope-definition"> Hurreyscope is an innovative
    tool created to <br class="block md:hidden"> transform the academic
    journey of students, <br class="block md:hidden"> especially <br class="hidden md:block lg:hidden"> in their
    early <br class="hidden lg:block"> years. By <br class="block md:hidden"> emphasizing the importance of learning
    <br class="block md:hidden"> concepts and their practical
    <br class="hidden md:block lg:hidden">
    applications, <br class="block md:hidden"> students
    gain
    insight into their <br class="hidden lg:block"> education's <br class="block md:hidden"> relevance, fostering
    confidence for future <br class="block lg:hidden"> challenges
  </p>
  <!-- <button class="try_it_button" type="button" id="try-now-button"> Try it now</button> -->

</section>