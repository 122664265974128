import { Component } from '@angular/core';
import { HurreyScopePotentialComponent } from '../../hurrey-scope-inner-pages/hurrey-scope-potential/hurrey-scope-potential.component';
import { IntroductionHurreyScopeComponent } from '../../hurrey-scope-inner-pages/introduction-hurrey-scope/introduction-hurrey-scope.component';
import { HurreyScopeFeaturesComponent } from '../../hurrey-scope-inner-pages/hurrey-scope-features/hurrey-scope-features.component';
import { ScienceBehindHurreyScopeComponent } from '../../hurrey-scope-inner-pages/science-behind-hurrey-scope/science-behind-hurrey-scope.component';
import { BuildYourJourneyComponent } from '../../hurrey-scope-inner-pages/build-your-journey/build-your-journey.component';

@Component({
  selector: 'app-hurrey-scope',
  standalone: true,
  imports: [HurreyScopePotentialComponent, IntroductionHurreyScopeComponent, HurreyScopeFeaturesComponent, ScienceBehindHurreyScopeComponent, BuildYourJourneyComponent],
  templateUrl: './hurrey-scope.component.html',
  styleUrl: './hurrey-scope.component.scss'
})
export class HurreyScopeComponent {

}
