<section class="scrollable-container" id="atomo-implementation-container">
  <div class="scroll_wrapper" id="atomo-implementation-wrapper"
    [style.backgroundColor]="implementationSlidesContent[currentSlide].bgColor">

    <div class="pattern_container hidden md:flex">
      <span class="pattern" [ngClass]="{'active': currentSlide==i}" *ngFor="let page of [1,2,3,4,5];let i = index"
        (click)="moveToSlide(i)"></span>
    </div>

    <div class="implementation_content" id="atomo-implementation-content">
      <div class="content_container" id="atomo-implementation-content-outer-wrap">
        <div class="content_sub_container" id="atomo-implementation-content-inner-wrap">
          <div class="right_content">
            <p class="text-hurrey-24 md:text-hurrey-36 lg:text-hurrey-48 text-white"
              id="atomo-implementation-content-title">
              {{implementationSlidesContent[currentSlide].head}}
            </p>
          </div>
          <div class="left_content">
            <p class="text-hurrey-12 md:text-hurrey-24 lg:text-hurrey-36 text-white"
              id="atomo-implementation-content-description">
              {{implementationSlidesContent[currentSlide].description}}</p>
          </div>
        </div>
      </div>
    </div>


    <div class="numbersDiv">
      <p class="text-[80vh] text-white opacity-20">{{currentSlide+1}}</p>
    </div>
  </div>
</section>