<div class="black_div"></div>

@if (!blogLoading) {
  @if (blogDetails) {
    <div class="content_wrapper" >
      <div class="content" [innerHTML]="blogContent"></div>
    </div>
  }@else {
    <div class="no_content">
      <img src="../../../../assets/images/no_data.svg" alt="No Content" height="300" width="300">
      <p>No Content !!</p>
    </div>
  }
}@else {
  <div class="spinner_div">
    <h-spinner></h-spinner>
  </div>
}
