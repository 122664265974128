import { Component } from '@angular/core';

@Component({
  selector: 'app-science-behind-physics-solver',
  standalone: true,
  imports: [],
  templateUrl: './science-behind-physics-solver.component.html',
  styleUrl: './science-behind-physics-solver.component.scss'
})
export class ScienceBehindPhysicsSolverComponent {

}
