import { Component } from '@angular/core';
import { OurPartnerFoundriesComponent } from '../our-partner-foundries/our-partner-foundries.component';
import { TrustedByCustomersComponent } from '../trusted-by-customers/trusted-by-customers.component';
import { NumberWhichMatterComponent } from '../number-which-matter/number-which-matter.component';
import { AiPoweredEducationalComponent } from '../ai-powered-educational/ai-powered-educational.component';
import { AtomoAiComponent } from '../atomo-ai/atomo-ai.component';
import { AtomosUspComponent } from '../atomos-usp/atomos-usp.component';
import { BeneficiariesPartnersComponent } from '../beneficiaries-partners/beneficiaries-partners.component';
import { EducationalExcellanceComponent } from '../educational-excellance/educational-excellance.component';
import { FeaturedOnComponent } from '../featured-on/featured-on.component';
import { HowDoWeImplementComponent } from '../how-do-we-implement/how-do-we-implement.component';
import { OneRepositoryUnifiedExpComponent } from '../one-repository-unified-exp/one-repository-unified-exp.component';
import { OurInnovationsComponent } from '../our-innovations/our-innovations.component';
import { OurMissionComponent } from '../our-mission/our-mission.component';
import { AiPoweredEducationV2Component } from '../ai-powered-education-v2/ai-powered-education-v2.component';

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [
    OurPartnerFoundriesComponent,
    TrustedByCustomersComponent,
    NumberWhichMatterComponent,
    FeaturedOnComponent,
    OurMissionComponent,
    OurInnovationsComponent,
    OneRepositoryUnifiedExpComponent,
    BeneficiariesPartnersComponent,
    AtomoAiComponent,
    AiPoweredEducationalComponent,
    AtomosUspComponent,
    EducationalExcellanceComponent,
    HowDoWeImplementComponent,
    AiPoweredEducationV2Component,
  ],
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss',
})
export class LandingComponent {}
