<div class=" science_behind_language_container flex flex-col gap-6 px-4  md:px-14 lg:px-[108px] py-20 lg:py-24">
  <p class="text-black font-bold text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36"> Science behind Language Learner
  </p>
  <div class="grid grid-cols-12">
    <p class="text-black text-hurrey-16 col-span-12 lg:col-span-6"> Our language solver is built on data analysis and
      user behavior tracking. By
      analyzing word difficulty and user proficiency, we tailor learning experiences. Our platform identifies
      challenging
      content and offers targeted guidance, helping users improve their language skills effectively.
    </p>
  </div>
</div>