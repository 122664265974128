import { Component } from '@angular/core';
import { TooltipPosition } from '../../models/tooltip-pos.enum';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-hurrey-tooltip',
  standalone: true,
  imports: [NgClass],
  templateUrl: './hurrey-tooltip.component.html',
  styleUrl: './hurrey-tooltip.component.scss',
})
export class HurreyTooltipComponent {
  position: TooltipPosition = TooltipPosition.DEFAULT;
  tooltip = '';
  left = 0;
  top = 0;
  visible = false;

  ngOnInit(): void {
    console.log(this.tooltip);
  }
}
