import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  IconCircles,
  IconFileText,
  IconBusinessplan,
  IconBrandSpeedtest,
  IconUsers,
  IconCheckbox,
  IconBus,
  IconSchool,
  IconBellRinging,
  IconChartHistogram,
  IconLanguage,
  IconBookDownload,
  IconBuildingCarousel,
  IconLayersUnion,
  IconClipboardText,
  IconAffiliate,
  IconUserScan,
  IconChartBar,
  IconScoreboard,
  IconDeviceMobile,
  IconCamera,
  IconBrain,
  IconDeviceIpadQuestion,
  IconUser,
  IconSquareRoot2,
  IconGraph,
  IconFileCode,
  IconNotebook,
  IconPhoneCalling,
  IconTableOptions,
  IconClipboardData,
  IconHeartHandshake,
  IconBuilding,
  IconGavel,
  IconBooks,
  IconHomeStats,
  IconBrandPython,
  IconParachute,
  IconCopy,
  IconFriends,
  IconAd2,
  IconBrandTeams,
  IconExchange,
  IconElevator,
  IconChevronDown,
  IconArrowLeft,
  IconArrowRight,
} from 'angular-tabler-icons/icons';
import { OptionIcons } from 'angular-tabler-icons/lib/options.interfaces';
import { TablerIconsModule } from 'angular-tabler-icons';

const icons: OptionIcons = {
  IconCircles,
  IconFileText,
  IconBusinessplan,
  IconBrandSpeedtest,
  IconUsers,
  IconCheckbox,
  IconBus,
  IconSchool,
  IconBellRinging,
  IconChartHistogram,
  IconLanguage,
  IconBookDownload,
  IconBuildingCarousel,
  IconLayersUnion,
  IconClipboardText,
  IconAffiliate,
  IconUserScan,
  IconChartBar,
  IconScoreboard,
  IconDeviceMobile,
  IconCamera,
  IconBrain,
  IconDeviceIpadQuestion,
  IconUser,
  IconSquareRoot2,
  IconGraph,
  IconFileCode,
  IconNotebook,
  IconPhoneCalling,
  IconTableOptions,
  IconClipboardData,
  IconHeartHandshake,
  IconBuilding,
  IconGavel,
  IconBooks,
  IconHomeStats,
  IconBrandPython,
  IconParachute,
  IconCopy,
  IconFriends,
  IconAd2,
  IconBrandTeams,
  IconExchange,
  IconElevator,
  IconChevronDown,
  IconArrowLeft,
  IconArrowRight,
};

@NgModule({
  declarations: [],
  imports: [CommonModule, TablerIconsModule.pick(icons)],
  exports: [TablerIconsModule],
})
export class IconsModule { }
