import {
  Component,
  inject,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  collection,
  collectionData,
  Firestore,
  query,
  where,
} from '@angular/fire/firestore';
import {
  SeoBlog,
  SeoBlogConverter,
} from '../../../shared/models/seo-blog.model';
import { take } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SpinnerComponent } from '../../../shared/reusable-components/spinner/spinner.component';

@Component({
  selector: 'app-blog',
  standalone: true,
  imports: [SpinnerComponent],
  templateUrl: './blog.component.html',
  styleUrl: './blog.component.scss',
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class BlogComponent implements OnInit {
  @Input() slug!: string;

  blogDetails!: SeoBlog;

  blogLoading: boolean = false;

  blogContent: SafeHtml = '';

  private firestore: Firestore = inject(Firestore);

  private sanatizer_: DomSanitizer = inject(DomSanitizer);

  ngOnInit(): void {
    console.log(this.slug)
    this.blogLoading = true;
    this.fetchBlog().subscribe({
      next: (blogs: SeoBlog[]) => {
        this.blogLoading = false;
        this.blogDetails = blogs[0];
        this.blogContent = this.sanatizer_.bypassSecurityTrustHtml(this.blogDetails.body)
      },
      error: () => {
        this.blogLoading = false;
      },
    });
  }

  fetchBlog() {
    const blogsQuery = query(
      collection(this.firestore, `blogs`).withConverter(SeoBlogConverter),
      where('project', '==', 'atomo-site'),
      where('slug', '==', this.slug)
    );
    return collectionData(blogsQuery).pipe(take(1));
  }
}
