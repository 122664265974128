<section class="bg-[#F1EEFF]" id="atomo-modules">
  <div class="flex flex-col py-10 px-4 lg:px-[108px] md:px-14 md:py-14 lg:py-24 gap-6 md:gap-10">
    <div class="flex flex-col gap-2 md:gap-4">
      <p class="text-hurrey-16 text-grey" id="atomo_ai_saas"> AI SAAS</p>
      <p class="text-hurrey-36 text-purple_100 text-opacity-60 lg:text-opacity-100" id="atomo_ai_saas_tech_stack">Our
        Tech Stack</p>
    </div>

    <div class="flex flex-col items-center justify-center">
      <div class="grid grid-cols-2 gap-x-4 gap-y-6 lg:gap-x-6 lg:gap-y-10 ">
        <ng-container *ngFor="let item of tech_stack , let i= index">
          <div (click)="goToProduct(item.route)"
            class="col-span-2 md:col-span-1 flex items-center justify-center rounded-md lg:rounded-xl px-5 py-8 lg:py-16 lg:px-6 min-h-[160px] lg:min-h-[264px] cursor-pointer"
            [ngStyle]="{
      'background-color':
        i == 0 ? '#FFB600' :
        i == 1 ? '#E31C79' :
        i == 2 ? '#6B4EFF' :
        i == 3 ? '#307FE2' :
        i == 4 ? '#E31C79' :
        i == 5 ? '#FFB600' :
        i == 6 ? '#307FE2' :
        '#6B4EFF'
    }">
            <div class="flex gap-3 items-center justify-center ">
              <div class="flex flex-col gap-4">
                <!-- [routerLink]="item.route" -->
                <p class="text-white text-hurrey-16 lg:text-hurrey-24" [id]="item.title"> {{item.title}} </p>
                <p class="text-white text-hurrey-12 lg:text-hurrey-16 max-w-[80%]" [id]="item.title+'_description'">
                  {{item.description}}</p>
              </div>
              <img [src]="item.img">

            </div>
          </div>
        </ng-container>
      </div>
    </div>

  </div>