<div class="grid grid-cols-12">
  <div
    class="col-span-12 flex items-center justify-center md:items-center md:justify-center px-4 py-12 md:px-14 lg:px-[108px] md:py-16 md:pb-8">
    <p class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36 text-purple_100" id="atomo_ai_title">AI-Powered
      Educational Advancement
    </p>
  </div>
  <div class="col-span-12 lg:col-span-6 flex justify-center items-center">
    <img src="../../../../assets/media/konnect_anim.gif" alt="konnect" loading="lazy">
  </div>
  <div class="col-span-12 lg:col-span-6">
    <div [id]="card.title" class="use_case_accordion px-4 md:px-14 lg:px-6"
      *ngFor="let card of aiEductionalContentCards;let i =index">
      <div class="accordion_head py-6 cursor-pointer" (click)="onCardClick(i)">
        <div class="flex justify-between">
          <div class="flex gap-2 lg:gap-6">
            <div style="flex: 1 0 0;">
              <p class="text-hurrey-16 md:text-hurrey-24 lg:text-hurrey-28" style="flex: 1 0 0;"
                [class.text-sky_dark]="!card.isExpanded">{{card.title}}</p>
            </div>
          </div>

          <div class="flex justify-center items-center">
            <i-tabler name="chevron-down" class="custom-icon" [ngClass]="{'up': card.isExpanded}"></i-tabler>
          </div>
        </div>
      </div>
      <div *ngIf="card.isExpanded" [@expandOnEnter] [@collapseOnLeave] class="accordion_body flex flex-col gap-6 pb-6">
        <ng-container>
          <p class="text-hurrey-16 md:text-hurrey-20 lg:text-hurrey-24">{{card.description}}</p>
        </ng-container>
      </div>
    </div>
  </div>
</div>