import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { slideInDownOnEnterAnimation } from 'angular-animations';
import { ourMission } from '../../../shared/models/our-mission.model';

@Component({
  selector: 'our-mission',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './our-mission.component.html',
  styleUrl: './our-mission.component.scss',
  animations: [slideInDownOnEnterAnimation({ anchor: 'slideInDown' })],
})
export class OurMissionComponent {
  selectedTab: string = 'tab1';

  selectTab(tabName: string) {
    this.selectedTab = tabName;
  }

  details: ourMission[] = [
    {
      title: 'Atomo for Startups',
      mission: 'Atomo for startup Mission',
      about:
        'Embark on a transformative journey with Schools as we offer an exclusive opportunity for growth and innovation.',
      description:
        'As your school undergoes an AI upgrade, a neighboring school will also receive a complimentary upgrade. This dual transformation enhances both your institution and the local educational landscape, fostering a technologically empowered future for all.',
      number: '1',
    },

    {
      title: 'Nation Building',
      mission: 'Atomo’s Nation Building Mission',
      about:
        "For nearly 50 years, the foundation of technological nation-building has been the effective harnessing of artificial intelligence's transformative power.",
      description:
        'At Atomo, education is the catalyst for national development. By focusing on empowering individuals with essential skills, we contribute to building a highly skilled workforce, fostering innovation, and playing a crucial role in the progress of our nation.',
      number: '2',
    },

    {
      title: 'Green School',
      mission: 'Atomo’s Green School Mission',
      about:
        'Atomo spearheads an educational revolution by seamlessly integrating sustainability and reshaping the learning landscape with innovative AI.',
      description:
        "Atomo seamlessly integrates into various setups, providing compact and energy-efficient hardware for sustainable education. By eliminating complex infrastructures, Atomo's commitment to sustainability aligns with eco-friendly standards, reshaping the learning landscape for a brighter future.",
      number: '3',
    },
  ];
}
