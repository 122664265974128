import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { title } from 'process';
import { hurreyScope_potential } from '../../../shared/models/hurreyScope_potential.model';
import { hurreyScope_benefits } from '../../../shared/models/hurreyScope_benefits.model';
import { hurreyScope_unveiling_process } from '../../../shared/models/hurreyScope_unveiling_process.model';

@Component({
  selector: 'hurrey-scope-features',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './hurrey-scope-features.component.html',
  styleUrl: './hurrey-scope-features.component.scss',
})
export class HurreyScopeFeaturesComponent {
  potential: hurreyScope_potential[] = [
    {
      img: '../../../../assets/images/analyze_conceptual.svg',
      title: 'Analyze Conceptual ',
      topic: 'Understanding',
      description:
        "Hurreyscope explores the depth of students' knowledge across various concepts, offering insights into their strengths and weaknesses.",
    },
    {
      img: '../../../../assets/images/identify_impactful.svg',
      title: 'Identify Impactful ',
      topic: 'Topics',
      description:
        "Using advanced algorithms, Hurreyscope identifies topics where students' strengths and weaknesses can significantly impact their learning journey.",
    },
    {
      img: '../../../../assets/images/predict_future.svg',
      title: 'Predict Future ',
      topic: 'Performance',
      description:
        "Leveraging performance data and predictive analytics, Hurreyscope forecasts students' academic outcomes, thereby enabling proactive measures for improvement.",
    },
  ];

  benefits: hurreyScope_benefits[] = [
    {
      img: '../../../../assets/images/improve_academic _performance.webp',
      title: 'Improve Academic',
      topic: 'Performance',
      description:
        'Hurreyscope empowers students to concentrate on achieving conceptual clarity, thereby enhancing their academic performance and promoting long-term success.',
    },
    {
      img: '../../../../assets/images/personalized_learning_paths.webp',
      title: 'Personalized Learning',
      topic: ' Paths',
      description:
        'Tailored recommendations assist students in prioritizing areas that require attention, thereby ensuring efficient and effective learning experiences.',
    },
    {
      img: '../../../../assets/images/proactive_performance_management.webp',
      title: 'Proactive Performance',
      topic: 'Management',
      description:
        'The early identification of potential learning gaps enables the implementation of proactive measures to address weaknesses and optimize future outcomes.',
    },
  ];

  unveiling_process: hurreyScope_unveiling_process[] = [
    {
      img: '../../../../assets/images/assess_conceptual_proficiency.svg',
      step: 'Step 1 ',
      title: ' Assess Conceptual Proficiency',
      description:
        "Hurreyscope constructs a personalized knowledge matrix based on students' strengths and weaknesses, derived from their input or existing performance data.",
    },
    {
      img: '../../../../assets/images/analyze_future_learning.svg',
      step: 'Step 2',
      title: 'Analyze Future Learning',
      description:
        "By examining upcoming chapters and topics, Hurreyscope evaluates how students' strengths and weaknesses may impact their comprehension and performance.",
    },
    {
      img: '../../../../assets/images/predict_academic_trajectory.svg',
      step: 'Step 3',
      title: 'Predict Academic Trajectory',
      description:
        "Utilizing the personalized knowledge matrix and predictive analytics, Hurreyscope projects students' future performance, facilitating proactive measures for academic enhancement and success.",
    },
  ];
}
