import { Component } from '@angular/core';

@Component({
  selector: 'introduction-hurrey-scope',
  standalone: true,
  imports: [],
  templateUrl: './introduction-hurrey-scope.component.html',
  styleUrl: './introduction-hurrey-scope.component.scss'
})
export class IntroductionHurreyScopeComponent {

}
