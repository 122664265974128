import { Component } from '@angular/core';

@Component({
  selector: 'math-solution',
  standalone: true,
  imports: [],
  templateUrl: './math-solution.component.html',
  styleUrl: './math-solution.component.scss'
})
export class MathSolutionComponent {

}
